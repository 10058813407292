import * as Sentry from '@sentry/node';
import { nanoid } from 'nanoid';
import { tuple } from './type';

const SentryErrorLevels = tuple(
  'fatal',
  'error',
  'warning',
  'log',
  'info',
  'debug',
  'critical',
);
type SentryErrorLevel = (typeof SentryErrorLevels)[number];

interface SentryErrorOptionProps {
  level?: SentryErrorLevel;
}

const sentryErrorDefaultProps: SentryErrorOptionProps = {
  level: 'error',
};

export class SentryLogger {
  static error(
    e: Error,
    options: SentryErrorOptionProps = sentryErrorDefaultProps,
  ) {
    e.name = `[${typeof window === 'undefined' ? 'Server' : 'Client'}] ${
      e.name || ''
    }`;
    Sentry.withScope((scope) => {
      const { level } = options;
      scope.setLevel(level as Sentry.Severity);
      Sentry.captureException(e);
    });
  }

  static setUser(user: Sentry.User) {
    Sentry.setUser(user);
  }

  static setContext(content: Record<string, any>) {
    Sentry.setContext('character', content);
  }

  static getTraceId() {
    return nanoid();
  }
}
