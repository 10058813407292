/*
 * @Date: 2024-01-19 21:36:46
 * @LastEditors: wangkai wangkai04@rd.netease.com
 * @LastEditTime: 2024-01-22 11:14:03
 * @Description: 消息列表为空
 */
import { useIntl } from 'react-intl';
import styles from './styles/Empty.module.scss';
import { notificationIntlMessgaes } from './constants/intl';
import { NotificationEmpty } from '@/constants';

interface EmptyProps {
  customStyle?: React.CSSProperties;
}

const Empty: React.FC<EmptyProps> = ({ customStyle }) => {
  const { formatMessage } = useIntl();
  return (
    <div className={styles.empty} style={customStyle}>
      <div className={styles.emptyContent}>
        <div className={styles.emptyImg}>
          <NotificationEmpty />
        </div>
        <div className={styles.emptyTitle}>{formatMessage(notificationIntlMessgaes.noMessage)}</div>
      </div>
    </div>
  );
};

export default Empty;
