import { tuple } from '@/utils/type';

export const storagePlatforms = tuple('YouTube', 'Instagram', 'Facebook', 'Twitter', 'TikTok', 'Twitch');

export enum StoragePlatforms {
  YouTube = 1,
  Instagram = 2,
  Facebook = 3,
  Twitter = 4,
  TikTok = 6,
  Twitch = 7,
  BigoLive = 5,
  Bilibili = 8,
  Others = 9,
}

export type StoragePlatform = (typeof storagePlatforms)[number];

export const platform: Record<number, string> = {
  1: 'YouTube',
  2: 'Instagram',
  3: 'Facebook',
  4: 'Twitter',
  6: 'TikTok',
  7: 'Twitch',
};

export const shouldDisplayKeywordsPlatforms = [StoragePlatforms.YouTube, StoragePlatforms.TikTok];

export enum AllPlatforms {
  YouTube = 'YouTube',
  TikTok = 'TikTok',
  Instagram = 'Instagram',
  Twitch = 'Twitch',
  Twitter = 'Twitter',
  Facebook = 'Facebook',
  Others = 'Others',
}
