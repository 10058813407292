/**
 * 注册激活邮件发送时间
 */
export const ActiveEmailTimeKey = 'YOUDAOADS_ADVERTISE_PLATFORM_RST_PSW_TIME';

/**
 * 注册激活邮件倒计时
 */
export const REGISTER_TIME_KEY = 'YOUDAOADS_ADVERTISE_PLATFORM_REGISTER_TIME';

/**
 * 重置密码邮件倒计时
 */
export const RESET_PASSWORD_TIME_KEY =
  'YOUDAOADS_ADVERTISE_PLATFORM_RST_PSW_TIME';
