export const TiktokOutlineIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M9.88571 9.8725V9.0978C9.61391 9.06332 9.34027 9.04526 9.06629 9.0437C5.72586 9.0437 3 11.7573 3 15.1071C3 17.1535 4.02283 18.9734 5.58593 20.0719C4.53858 18.9542 3.95665 17.4792 3.95863 15.9474C3.96945 12.6409 6.60948 9.94824 9.88571 9.87322V9.8725Z"
        fill="#00FAF0"
      />
      <path
        d="M10.0256 18.6942C10.74 18.6929 11.4262 18.4151 11.9404 17.919C12.4546 17.423 12.7569 16.7472 12.7839 16.0333V2.84034H15.1982C15.1453 2.56341 15.1199 2.28192 15.1224 2H11.826V15.1929C11.799 15.907 11.4966 16.5829 10.9822 17.0789C10.4679 17.575 9.78152 17.8527 9.06694 17.8539C8.61948 17.8542 8.17898 17.7431 7.78516 17.5307C8.03843 17.8898 8.37413 18.1828 8.7641 18.3853C9.15407 18.5877 9.58619 18.6937 10.0256 18.6942ZM19.7122 7.32045V6.58831C18.8241 6.59022 17.955 6.33193 17.2121 5.84535C17.8682 6.58946 18.7436 7.10598 19.7122 7.32045Z"
        fill="#00FAF0"
      />
      <path
        d="M17.2236 5.84536C16.4953 5.01387 16.0932 3.94645 16.0919 2.84106H15.209C15.3263 3.45131 15.5651 4.03174 15.9112 4.54785C16.2573 5.06396 16.7036 5.50521 17.2236 5.84536ZM9.06625 12.3286C8.70261 12.3283 8.34248 12.3997 8.00643 12.5386C7.67039 12.6776 7.36503 12.8814 7.10779 13.1385C6.85056 13.3955 6.6465 13.7007 6.50727 14.0366C6.36805 14.3726 6.29639 14.7326 6.29639 15.0963C6.29658 15.601 6.43539 16.096 6.69768 16.5272C6.95997 16.9584 7.33565 17.3093 7.78375 17.5415C7.44362 17.0712 7.25901 16.5063 7.25574 15.9258C7.25574 15.5622 7.3274 15.2021 7.46663 14.8661C7.60585 14.5302 7.80991 14.225 8.06715 13.968C8.32438 13.7109 8.62975 13.5071 8.96579 13.3681C9.30183 13.2292 9.66196 13.1578 10.0256 13.1581C10.3055 13.1581 10.5854 13.2014 10.8443 13.2872V9.92658C10.5727 9.89238 10.2993 9.87455 10.0256 9.87321H9.88495V12.4577C9.62089 12.3704 9.34436 12.3268 9.06625 12.3286Z"
        fill="#FE2C55"
      />
      <path
        d="M19.7124 7.32043V9.884C17.9993 9.884 16.4254 9.33436 15.132 8.40818V15.0963C15.132 18.436 12.417 21.1604 9.06574 21.1604C7.82353 21.1612 6.61153 20.7775 5.59619 20.0618C6.70558 21.2462 8.2795 22 10.0352 22C13.3763 22 16.1022 19.2864 16.1022 15.9366V9.24852C17.4369 10.2076 19.039 10.7236 20.6826 10.7236V7.42791C20.3567 7.41995 20.0321 7.38399 19.7124 7.32043Z"
        fill="#FE2C55"
      />
      <path
        d="M15.132 15.097V8.40819C16.4667 9.36756 18.0688 9.88377 19.7124 9.88401V7.32045C18.7426 7.10872 17.8661 6.59174 17.2116 5.84535C16.6893 5.50868 16.2418 5.06818 15.8969 4.55122C15.5521 4.03426 15.3173 3.4519 15.2071 2.84033H12.7935V16.0325C12.7665 16.7465 12.4642 17.4223 11.95 17.9183C11.4359 18.4143 10.7497 18.6921 10.0352 18.6935C9.59648 18.6952 9.16378 18.5913 8.77361 18.3907C8.38343 18.1901 8.04723 17.8986 7.79334 17.5408C7.34621 17.3074 6.97137 16.9563 6.70935 16.5253C6.44733 16.0943 6.3081 15.5999 6.3067 15.0955C6.3067 14.732 6.37834 14.3719 6.51752 14.0361C6.6567 13.7002 6.86069 13.395 7.11785 13.138C7.37501 12.8809 7.68029 12.6771 8.01625 12.5381C8.35221 12.3991 8.71226 12.3277 9.07584 12.3278C9.35572 12.3278 9.63631 12.3711 9.89454 12.4577V9.87247C6.61903 9.94749 3.979 12.6402 3.979 15.9251C3.979 17.5192 4.59285 18.9734 5.60558 20.051C6.62194 20.7651 7.83372 21.1484 9.07584 21.1489C12.4055 21.1597 15.132 18.4345 15.132 15.0963V15.097Z"
        fill="#111111"
      />
    </svg>
  );
};

export const TiktokGrayIcon = () => {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="30" height="30" rx="15" fill="#B7B9BF" />
      <path
        d="M20.1028 13.3619C21.3249 14.2387 22.8221 14.7545 24.4391 14.7545V11.6318C24.1331 11.6319 23.8278 11.5998 23.5284 11.5362V13.9942C21.9115 13.9942 20.4146 13.4784 19.1921 12.6017V18.9743C19.1921 22.1622 16.617 24.7463 13.4406 24.7463C12.2554 24.7463 11.1538 24.3867 10.2388 23.77C11.2832 24.8417 12.7397 25.5065 14.3511 25.5065C17.5276 25.5065 20.1029 22.9224 20.1029 19.7344V13.3619H20.1028ZM21.2262 10.2115C20.6016 9.52669 20.1915 8.64173 20.1028 7.66337V7.26172H19.2398C19.457 8.50522 20.1979 9.56759 21.2262 10.2115ZM12.2478 21.3237C11.8989 20.8645 11.7103 20.3028 11.7111 19.7253C11.7111 18.2672 12.889 17.0851 14.3421 17.0851C14.6129 17.085 14.8821 17.1266 15.1402 17.2088V14.0163C14.8386 13.9748 14.5342 13.9572 14.2299 13.9636V16.4485C13.9716 16.3664 13.7023 16.3246 13.4314 16.3249C11.9783 16.3249 10.8005 17.5069 10.8005 18.9651C10.8005 19.9962 11.3893 20.8889 12.2478 21.3237Z"
        fill="white"
      />
      <path
        d="M19.1917 12.6016C20.4141 13.4783 21.9111 13.9942 23.528 13.9942V11.5361C22.6255 11.3432 21.8265 10.8699 21.2257 10.2115C20.1974 9.56752 19.4566 8.50515 19.2394 7.26172H16.9725V19.7343C16.9674 21.1883 15.7916 22.3657 14.3416 22.3657C13.4871 22.3657 12.728 21.9569 12.2472 21.3237C11.3887 20.8889 10.8 19.9961 10.8 18.9652C10.8 17.5071 11.9778 16.3249 13.4309 16.3249C13.7093 16.3249 13.9777 16.3684 14.2294 16.4486V13.9637C11.1088 14.0284 8.59912 16.5873 8.59912 19.7343C8.59912 21.3053 9.22409 22.7295 10.2384 23.7701C11.1535 24.3867 12.2551 24.7463 13.4403 24.7463C16.6167 24.7463 19.1917 22.1621 19.1917 18.9743V12.6016H19.1917Z"
        fill="white"
      />
      <path
        d="M23.5285 11.536V10.8714C22.7146 10.8727 21.9167 10.6439 21.2262 10.2113C21.8375 10.883 22.6424 11.346 23.5285 11.536ZM19.2398 7.26165C19.2191 7.14281 19.2032 7.02319 19.1921 6.90311V6.50146H16.0623V18.9741C16.0573 20.428 14.8815 21.6053 13.4314 21.6053C13.0056 21.6053 12.6036 21.5039 12.2477 21.3237C12.7284 21.9568 13.4875 22.3655 14.342 22.3655C15.7919 22.3655 16.9679 21.1883 16.973 19.7343V7.26165H19.2398ZM14.23 13.9636V13.2561C13.9684 13.2202 13.7048 13.2022 13.4408 13.2023C10.2641 13.2023 7.68896 15.7865 7.68896 18.9741C7.68896 20.9726 8.70103 22.7338 10.2389 23.7699C9.2246 22.7293 8.59963 21.3051 8.59963 19.7342C8.59963 16.5872 11.1093 14.0283 14.23 13.9636Z"
        fill="white"
      />
    </svg>
  );
};

export const TiktokIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="ic">
      <rect
        id="image 81"
        x="1.00008"
        y="1.00002"
        width="28"
        height="28"
        rx="14"
        fill="#111927"
        stroke="white"
        strokeWidth="1.33333"
      />
      <g id="TikTok / Original">
        <path
          id="Vector"
          d="M18.6474 12.6547C19.7337 13.434 21.0646 13.8925 22.5019 13.8925V11.1168C22.2299 11.1168 21.9586 11.0883 21.6924 11.0318V13.2167C20.2552 13.2167 18.9245 12.7581 17.8379 11.9789V17.6434C17.8379 20.4771 15.5489 22.7741 12.7255 22.7741C11.672 22.7741 10.6928 22.4544 9.87939 21.9063C10.8078 22.8589 12.1024 23.4498 13.5348 23.4498C16.3584 23.4498 18.6475 21.1529 18.6475 18.3191V12.6547H18.6474ZM19.646 9.85425C19.0908 9.24555 18.7263 8.45892 18.6474 7.58926V7.23224H17.8803C18.0734 8.33757 18.732 9.2819 19.646 9.85425ZM11.6652 19.7318C11.355 19.3236 11.1874 18.8243 11.1882 18.3109C11.1882 17.0149 12.2351 15.9641 13.5268 15.9641C13.7675 15.964 14.0068 16.001 14.2362 16.0741V13.2363C13.9681 13.1994 13.6975 13.1837 13.4271 13.1895V15.3983C13.1975 15.3252 12.9581 15.2881 12.7173 15.2884C11.4257 15.2884 10.3787 16.3391 10.3787 17.6353C10.3787 18.5518 10.9021 19.3453 11.6652 19.7318Z"
          fill="#FF004F"
        />
        <path
          id="Vector_2"
          d="M17.8372 11.9788C18.9239 12.7581 20.2545 13.2166 21.6918 13.2166V11.0317C20.8895 10.8602 20.1793 10.4395 19.6453 9.85425C18.7313 9.28184 18.0727 8.33751 17.8796 7.23224H15.8647V18.3189C15.8601 19.6114 14.8149 20.658 13.526 20.658C12.7665 20.658 12.0917 20.2946 11.6644 19.7318C10.9013 19.3453 10.378 18.5517 10.378 17.6353C10.378 16.3393 11.4249 15.2884 12.7166 15.2884C12.964 15.2884 13.2026 15.3271 13.4263 15.3984V13.1895C10.6525 13.2471 8.42163 15.5216 8.42163 18.319C8.42163 19.7154 8.97716 20.9814 9.87879 21.9063C10.6922 22.4544 11.6714 22.7741 12.7249 22.7741C15.5484 22.7741 17.8373 20.477 17.8373 17.6434V11.9788H17.8372Z"
          fill="white"
        />
        <path
          id="Vector_3"
          d="M21.6921 11.0318V10.441C20.9687 10.4421 20.2594 10.2388 19.6456 9.85425C20.189 10.4512 20.9045 10.8629 21.6921 11.0318ZM17.88 7.2323C17.8615 7.12667 17.8474 7.02034 17.8376 6.9136V6.55658H15.0555V17.6434C15.051 18.9357 14.0059 19.9822 12.7169 19.9822C12.3385 19.9822 11.9812 19.8921 11.6647 19.7319C12.0921 20.2947 12.7668 20.658 13.5264 20.658C14.8152 20.658 15.8605 19.6115 15.865 18.3191V7.2323H17.88ZM13.4268 13.1896V12.5607C13.1943 12.5288 12.9599 12.5128 12.7253 12.5129C9.90154 12.5128 7.61255 14.8099 7.61255 17.6434C7.61255 19.4198 8.51216 20.9854 9.87919 21.9063C8.97756 20.9814 8.42203 19.7154 8.42203 18.319C8.42203 15.5217 10.6528 13.2471 13.4268 13.1896Z"
          fill="#79F6EF"
        />
      </g>
    </g>
  </svg>
);
