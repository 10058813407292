export enum IndustryTypes {
  OTHER, // 其他
  ELECTRICAL, // 3C及电器
  FMCG, // 快速消费品
  FOOD_DRINK, // 食品饮料
  CLOTHING, // 服装配饰
  MEDICAL, // 医疗
  BUSINESS_SERVICES, // 商务服务
  LIFE_SERVICES, // 生活服务
  ESTATE, // 房地产
  HOME_BUILDING, // 家居建材
  EDUCATION, // 教育培训
  TRAVEL, // 出行旅游
  SOCIAL_PUBLIC, // 社会公共
  GAME, // 游戏
  RETAIL, // 零售
  TRANSPORTATION, // 交通工具
  CAR, // 汽车
  AGRICULTURE, // 农林牧畜渔
  CHEMICAL_ENERGY, // 化工及能源
  ELECTRONIC_ELECTRICAL, // 电子电工
  EQUIPMENT, // 机械设备
  ENTERTAINMENT, // 文娱娱乐
  MEDIA_CONTENT, // 传媒及内容
  LOGISTICS, // 物流业
  COMMUNICATION, // 通信
  FINANCIAL, // 金融业
  FOOD, // 餐饮服务
  TOOL_SOFTWARE, // 工具类软件
  JOIN, // 招商加盟
}
export const industryZhLabels: Record<IndustryTypes, string> = {
  [IndustryTypes.OTHER]: '其他',
  [IndustryTypes.ELECTRICAL]: '3C及电器',
  [IndustryTypes.FMCG]: '快速消费品',
  [IndustryTypes.FOOD_DRINK]: '食品饮料',
  [IndustryTypes.CLOTHING]: '服装配饰',
  [IndustryTypes.MEDICAL]: '医疗',
  [IndustryTypes.BUSINESS_SERVICES]: '商务服务',
  [IndustryTypes.LIFE_SERVICES]: '生活服务',
  [IndustryTypes.ESTATE]: '房地产',
  [IndustryTypes.HOME_BUILDING]: '家居建材',
  [IndustryTypes.EDUCATION]: '教育培训',
  [IndustryTypes.TRAVEL]: '出行旅游',
  [IndustryTypes.SOCIAL_PUBLIC]: '社会公共',
  [IndustryTypes.GAME]: '游戏',
  [IndustryTypes.RETAIL]: '零售',
  [IndustryTypes.TRANSPORTATION]: '交通工具',
  [IndustryTypes.CAR]: '汽车',
  [IndustryTypes.AGRICULTURE]: '农林牧畜渔',
  [IndustryTypes.CHEMICAL_ENERGY]: '化工及能源',
  [IndustryTypes.ELECTRONIC_ELECTRICAL]: '电子电工',
  [IndustryTypes.EQUIPMENT]: '机械设备',
  [IndustryTypes.ENTERTAINMENT]: '文娱娱乐',
  [IndustryTypes.MEDIA_CONTENT]: '传媒及内容',
  [IndustryTypes.LOGISTICS]: '物流业',
  [IndustryTypes.COMMUNICATION]: '通信',
  [IndustryTypes.FINANCIAL]: '金融业',
  [IndustryTypes.FOOD]: '餐饮服务',
  [IndustryTypes.TOOL_SOFTWARE]: '工具类软件', // 工具类软件
  [IndustryTypes.JOIN]: '招商加盟', // 招商加盟
};

export const industryEnLabels = {
  [IndustryTypes.OTHER]: 'Others',
  [IndustryTypes.ELECTRICAL]: '3C & Electrical Appliances',
  [IndustryTypes.FMCG]: 'Fast-moving Consumer Goods',
  [IndustryTypes.FOOD_DRINK]: 'Food & Beverage',
  [IndustryTypes.CLOTHING]: 'Clothing & Accessories',
  [IndustryTypes.MEDICAL]: 'Medical Services',
  [IndustryTypes.BUSINESS_SERVICES]: 'Business Services',
  [IndustryTypes.LIFE_SERVICES]: 'Life Services',
  [IndustryTypes.ESTATE]: 'Real Estate',
  [IndustryTypes.HOME_BUILDING]: 'Home Building Materials',
  [IndustryTypes.EDUCATION]: 'Education & Training',
  [IndustryTypes.TRAVEL]: 'Travel',
  [IndustryTypes.SOCIAL_PUBLIC]: 'Social Public Services',
  [IndustryTypes.GAME]: 'Gaming',
  [IndustryTypes.RETAIL]: 'Retail',
  [IndustryTypes.TRANSPORTATION]: 'Transportation',
  [IndustryTypes.CAR]: 'Automotive',
  [IndustryTypes.AGRICULTURE]: 'Agriculture & Forestry & Fishing & Hunting',
  [IndustryTypes.CHEMICAL_ENERGY]: 'Chemical & Energy',
  [IndustryTypes.ELECTRONIC_ELECTRICAL]: 'Electronic Electrician',
  [IndustryTypes.EQUIPMENT]: 'Mechanical Equipment',
  [IndustryTypes.ENTERTAINMENT]: 'Cultural Entertainment',
  [IndustryTypes.MEDIA_CONTENT]: 'Media & Content',
  [IndustryTypes.LOGISTICS]: 'Logistics Industry',
  [IndustryTypes.COMMUNICATION]: 'Communications',
  [IndustryTypes.FINANCIAL]: 'Finance',
  [IndustryTypes.FOOD]: 'Food & Beverage Services',
  [IndustryTypes.TOOL_SOFTWARE]: 'Tool Software',
  [IndustryTypes.JOIN]: 'Investment',
};
export const industryLabelSort = [
  IndustryTypes.GAME, // 游戏 ++
  IndustryTypes.ELECTRICAL, // 3C及电器
  IndustryTypes.FMCG, // 快速消费品
  IndustryTypes.FOOD_DRINK, // 食品饮料
  IndustryTypes.CLOTHING, // 服装配饰
  IndustryTypes.MEDICAL, // 医疗
  IndustryTypes.BUSINESS_SERVICES, // 商务服务
  IndustryTypes.LIFE_SERVICES, // 生活服务
  IndustryTypes.ESTATE, // 房地产
  IndustryTypes.HOME_BUILDING, // 家居建材
  IndustryTypes.EDUCATION, // 教育培训
  IndustryTypes.TRAVEL, // 出行旅游
  IndustryTypes.SOCIAL_PUBLIC, // 社会公共
  IndustryTypes.RETAIL, // 零售
  IndustryTypes.TRANSPORTATION, // 交通工具
  IndustryTypes.CAR, // 汽车
  IndustryTypes.AGRICULTURE, // 农林牧畜渔
  IndustryTypes.CHEMICAL_ENERGY, // 化工及能源
  IndustryTypes.ELECTRONIC_ELECTRICAL, // 电子电工
  IndustryTypes.EQUIPMENT, // 机械设备
  IndustryTypes.ENTERTAINMENT, // 文娱娱乐
  IndustryTypes.MEDIA_CONTENT, // 传媒及内容
  IndustryTypes.LOGISTICS, // 物流业
  IndustryTypes.COMMUNICATION, // 通信
  IndustryTypes.FINANCIAL, // 金融业
  IndustryTypes.FOOD, // 餐饮服务
  IndustryTypes.TOOL_SOFTWARE, // 工具类软件
  IndustryTypes.JOIN, // 招商加盟
  IndustryTypes.OTHER, // 其他 ++
];
