import cookie from 'js-cookie';
import { divide, isNull, isUndefined } from 'lodash';
import getConfig from 'next/config';
import { EMAIL_REGEXP } from '../constants/regExp';
import dayjs from 'dayjs';
import NP from './np';

const { STATIC_ASSETS_URL } = getConfig().publicRuntimeConfig;

// 转化obj的key和value
export const keyToValue = (obj: Record<string, number | string>) => {
  const res: Record<string, number | string> = {};
  // eslint-disable-next-line guard-for-in, no-restricted-syntax
  for (const key in obj) {
    res[obj[key]] = key;
  }
  return res;
};
//增加cdn前缀
export function formatAssetUrl(url: string) {
  if (!STATIC_ASSETS_URL) return '/static' + url;
  return STATIC_ASSETS_URL + url;
}

interface LoaderParms {
  src: string;
  width: number;
  quality?: number;
}
// 转圈
export const myNextImgLoader = (params: LoaderParms) => `${params.src}?w=${params.width}&q=${params.quality || 100}`;

// 获取父级容器
export const getParentContainer = (triggerNode: HTMLElement | null, lever = 1) => {
  while (triggerNode && lever-- > 0) {
    triggerNode = triggerNode.parentElement;
  }
  return triggerNode || document.body;
};

export function isEmail(email: string) {
  return EMAIL_REGEXP.test(email);
}
//加密密码
export async function rsaEncrypt(password: string): Promise<string> {
  const { JSEncrypt } = await import('jsencrypt');
  const crypt = new JSEncrypt();
  crypt.setPublicKey(process.env.rsaPublicKey as string);
  return crypt.encrypt(password) as string;
}

export function isValidPassword(password: string) {
  const re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z0-9]{2,}$/;
  return re.test(password);
}

export function removeUserCookies() {
  cookie.remove('USER_EMAIL');
  cookie.remove('USER_ID');
  cookie.remove('USER_TYPE');
  cookie.remove('USER_STATUS');
  cookie.remove('AUTH_TOKEN');
}

//nubmer加逗号
export function formatNumber(value: string | number, fractionDigits: number, moneny?: boolean) {
  if (isNaN(Number(value))) return value;
  const options = moneny
    ? {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits,
      }
    : {
        maximumFractionDigits: fractionDigits,
        minimumFractionDigits: fractionDigits,
      };
  return Number(value).toLocaleString('en', options);
}

interface FormatUnitNumberProps<T> {
  value: T;
  originFractionDigits?: number;
  largeNumberFractionDigits?: number;
}

export const customerToFixed = (value: number, fractionDigits = 0) => {
  const tmpPow = Math.pow(10, fractionDigits);
  const tmpValue = Math.round(NP.times(value, tmpPow));
  return NP.divide(tmpValue, tmpPow).toFixed(fractionDigits);
};

//带M,K后缀的数字格式化
export const formatUnitNumber = <T>({ value, originFractionDigits = 2, largeNumberFractionDigits = 2 }: FormatUnitNumberProps<T>): string | T => {
  const numberValue = Number(value);

  if (isNaN(numberValue)) {
    return value;
  }

  if (numberValue >= 1000000) {
    return customerToFixed(divide(numberValue, 1000000), largeNumberFractionDigits) + 'M';
  }

  if (numberValue >= 1000) {
    return customerToFixed(divide(numberValue, 1000), largeNumberFractionDigits) + 'K';
  }

  return customerToFixed(numberValue, originFractionDigits);
};

export const formatUnitNumberWithDigits = <T>(value: T, originFractionDigits = 2): string | T => {
  return formatUnitNumber({
    value,
    originFractionDigits,
    largeNumberFractionDigits: 2,
  });
};

/**
 * 数组去重
 */
export function uniqueArray<T>(arr: T[]): T[] {
  return Array.from(new Set(arr));
}

export const formatFileSize = (size: number, defaultText = '-') => {
  if (!size) return defaultText;
  return size > 1024 * 1024 ? Math.round(size / 1024 / 1024) + 'MB' : size > 1024 ? Math.round(size / 1024) + 'KB' : size + 'B';
};

export const renderEachKey = (obj: Record<string | number, string>, v: number | string, locale: string, empty = '-') => {
  // 如果key是国家
  return typeof v === 'string' ? obj[v] || (locale === 'zh-CN' ? '其他' : 'Others') : obj[v] || empty;
};
//判断是否是服务端
export function isServer() {
  return typeof window === 'undefined';
}
export const getContainerById = (id: string) => {
  if (isServer()) return {} as HTMLElement;
  return document.getElementById(id) as HTMLElement;
};
export const getWrapperContainer = () => {
  return getContainerById('wrapper');
};

export const formatDateFromDotToLine = (date: string) => {
  return date.replace(/\./g, '-');
};

export const getTextDomWidth = (str: string, fontSize?: number | string, fontFamily?: string) => {
  if (!fontSize) {
    fontSize = 14;
  }
  const spanEle = document.createElement('span');
  if (typeof fontSize === 'string') {
    spanEle.style.fontSize = fontSize;
  } else if (typeof fontSize === 'number') {
    spanEle.style.fontSize = fontSize + 'px';
  }
  if (fontFamily) {
    spanEle.style.fontFamily = fontFamily;
  }
  spanEle.style.whiteSpace = 'nowrap';
  spanEle.innerHTML = str;
  document.body.appendChild(spanEle);
  const width = spanEle.offsetWidth;
  spanEle.remove();
  return width;
};

// 降序
export function compare<T extends string | number>(a: T, b: T): number {
  if (a < b) {
    // 按某种排序标准进行比较, a 小于 b
    return 1;
  }
  if (a > b) {
    return -1;
  }
  // a must be equal to b
  return 0;
}

export function enumerateMonthsBetweenDates(startMonth: string, endMonth: string, format: string): string[] {
  const res: string[] = [];
  let startMonthTmp = dayjs(formatDateFromDotToLine(startMonth));
  const endDateTmp = dayjs(formatDateFromDotToLine(endMonth));
  while (endDateTmp > startMonthTmp || startMonthTmp.format('M') === endDateTmp.format('M')) {
    res.push(startMonthTmp.format(format));
    startMonthTmp = startMonthTmp.add(1, 'month');
  }
  return res;
}
// utils
export const getMonthsBetweenOneYearFromNow = () => {
  const end = dayjs().format('YYYY-MM-DD');
  const start = dayjs().subtract(1, 'year').format('YYYY-MM-DD');
  return enumerateMonthsBetweenDates(start, end, 'YYYY-MM');
};

/**
 * 连接字符串
 *
 * concatStr('hello,', 'world')
 * => hello,word
 */
export const concatStr = <T extends string[]>(...args: T) => {
  return args.join('');
};

// 获取echarts的y轴最大值
export const getEchartsIntervalMaxValue = (maxValue: number, sliptNumber = 5) => {
  return maxValue + (sliptNumber - (maxValue % sliptNumber));
};

export const getMaxFromArr = (arr: number[]) => {
  return Math.max(...arr);
};

// 截取字符串中的的英文和中文
export const splitString = (input: string) => {
  if (!input) return;
  // 使用正则表达式匹配英文和中文
  let english = input.match(/[a-zA-Z\s]+/g);
  let chinese = input.match(/[\u4e00-\u9fa5]+/g);

  // 检查匹配结果是否存在
  if (english && chinese) {
    // 返回匹配的英文和中文部分
    return {
      english: english.join(' ').trim(),
      chinese: chinese.join(''),
    };
  } else {
    // 如果没有匹配到英文或中文，返回错误信息
    return {
      // error: '输入的字符串必须包含英文和中文部分',
    };
  }
};
export function aTagDownload(url: string, filename: string) {
  const a: any = document.createElement('a');
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  a.remove();
  URL.revokeObjectURL(url);
}
export const getDate = (date: Date): string => {
  const Y = date.getFullYear() + '-';
  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
  const D = date.getDate();
  return Y + M + D;
};

// 加密解密
export function urlSafeBase64Encode(text: string) {
  return Buffer.from(text).toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

export function urlSafeBase64Decode(text: string) {
  return Buffer.from(text.replace(/-/g, '+').replace(/_/g, '/'), 'base64').toString('ascii');
}

export const changeXToTwitter = (str: string) => {
  return str.replace('https://x.com', 'https://twitter.com');
};
