export const YoutubeOutlineIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M22.54 6.40501C22.2865 5.45785 21.5421 4.71353 20.5949 4.45999C18.88 4 12 4 12 4C12 4 5.12002 4 3.405 4.45999C2.45785 4.71353 1.71353 5.45785 1.45999 6.40501C1 8.12002 1 11.7004 1 11.7004C1 11.7004 1 15.2807 1.45999 16.9957C1.71353 17.9429 2.45785 18.6872 3.405 18.9407C5.12002 19.4007 12 19.4007 12 19.4007C12 19.4007 18.88 19.4007 20.5949 18.9407C21.5421 18.6872 22.2865 17.9429 22.54 16.9957C23 15.2807 23 11.7004 23 11.7004C23 11.7004 22.9982 8.12002 22.54 6.40501Z"
        fill="#FF0000"
      />
      <path
        d="M9.79785 15.0004L15.5134 11.7008L9.79785 8.40112V15.0004Z"
        fill="white"
      />
    </svg>
  );
};

export const YoutubeGrayIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" fill="none">
      <rect x="1" y="1" width="30" height="30" rx="15" fill="#B7B9BF" />
      <g clipPath="url(#clip0_320_1845)">
        <path
          d="M25.1271 11.3996C25.0197 10.9953 24.8079 10.6263 24.5131 10.3295C24.2182 10.0328 23.8506 9.81865 23.4469 9.70862C21.9611 9.30908 16.022 9.30908 16.022 9.30908C16.022 9.30908 10.083 9.30908 8.59712 9.70862C8.1935 9.81865 7.82585 10.0328 7.53098 10.3295C7.23611 10.6263 7.02436 10.9953 6.91691 11.3996C6.51953 12.892 6.51953 16.004 6.51953 16.004C6.51953 16.004 6.51953 19.1161 6.91691 20.6084C7.02436 21.0127 7.23611 21.3817 7.53098 21.6785C7.82585 21.9753 8.1935 22.1894 8.59712 22.2994C10.083 22.699 16.022 22.699 16.022 22.699C16.022 22.699 21.9611 22.699 23.4469 22.2994C23.8506 22.1894 24.2182 21.9753 24.5131 21.6785C24.8079 21.3817 25.0197 21.0127 25.1271 20.6084C25.5245 19.1161 25.5245 16.004 25.5245 16.004C25.5245 16.004 25.5245 12.892 25.1271 11.3996Z"
          fill="white"
        />
        <path
          d="M14.0786 18.83V13.1782L19.0458 16.0041L14.0786 18.83Z"
          fill="#B7B9BF"
        />
      </g>
      <defs>
        <clipPath id="clip0_320_1845">
          <rect
            width="19.005"
            height="19.005"
            fill="white"
            transform="translate(6.50098 6.50146)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const YoutubeIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.00008"
      y="1.00002"
      width="28"
      height="28"
      rx="14"
      fill="#FF0302"
      stroke="white"
      strokeWidth="1.33333"
    />
    <g clipPath="url(#clip0_241_7612)">
      <path
        d="M23.1133 10.9108C23.0178 10.5514 22.8296 10.2234 22.5675 9.95962C22.3054 9.69583 21.9786 9.5055 21.6198 9.4077C20.2991 9.05255 15.0199 9.05255 15.0199 9.05255C15.0199 9.05255 9.74074 9.05255 8.41999 9.4077C8.06121 9.5055 7.73442 9.69583 7.47231 9.95962C7.2102 10.2234 7.02198 10.5514 6.92647 10.9108C6.57324 12.2373 6.57324 15.0036 6.57324 15.0036C6.57324 15.0036 6.57324 17.7699 6.92647 19.0964C7.02198 19.4558 7.2102 19.7838 7.47231 20.0476C7.73442 20.3114 8.06121 20.5017 8.41999 20.5995C9.74074 20.9547 15.0199 20.9547 15.0199 20.9547C15.0199 20.9547 20.2991 20.9547 21.6198 20.5995C21.9786 20.5017 22.3054 20.3114 22.5675 20.0476C22.8296 19.7838 23.0178 19.4558 23.1133 19.0964C23.4666 17.7699 23.4666 15.0036 23.4666 15.0036C23.4666 15.0036 23.4666 12.2373 23.1133 10.9108Z"
        fill="white"
      />
      <path
        d="M13.2925 17.5157V12.4918L17.7078 15.0037L13.2925 17.5157Z"
        fill="#FF0302"
      />
    </g>
    <defs>
      <clipPath id="clip0_241_7612">
        <rect
          width="16.8933"
          height="16.8933"
          fill="white"
          transform="translate(6.55688 6.55688)"
        />
      </clipPath>
    </defs>
  </svg>
);
