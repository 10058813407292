import { combineReducers } from '@reduxjs/toolkit';
import userReducer from './slice/user';
import commonReducer from './slice/common';

const rootReducer = combineReducers({
  user: userReducer,
  common: commonReducer,
});

export default rootReducer;
