import { keyToValue } from '@/utils/util';

export const NOT_REPORT_CODE = {
  /**
   * 非法请求系列异常 400
   */

  UNREGISTERED_USER: 400001, // 未注册的用户
  INACTIVE_KOL: 400002, // 未激活的KOL
  INCORRECT_PASSWORD: 400003, // 密码不正确
  BAD_BILLING_RANGE: 400004, // 给定的结算周期发生过结算
  USER_ACTIVED: 400005, // 该账户已激活
  ABNORMAL_ACTIVATION_CODE: 400006, // 激活码无法识别
  EXPIRED_ACTIVATION_CODE: 400007, // 激活码已过期
  ABNORMAL_RESET_CODE: 400008, // 重置码无法识别
  EXPIRED_RESET_CODE: 400009, // 重置码已过期
  BAD_FILE_TYPE: 400010, // 不支持的文件类型
  BAD_FILE_SIZE: 400011, // 文件过大
  BAD_ASSIGNMENT_PRICE: 400012, // 任务指派不合法，佣金单价被修改
  BAD_ASSIGNMENT_URL: 400013, // 任务指派不合法，推广链接被修改
  BAD_ASSIGNMENT_KOL: 400014, // 任务指派不合法，被取消过的kol不可再被选择

  UN_ACTIVE_MANAGER_USER: 400018, // 该用户是管理人员，但已经被管理员禁用
  ORDER_STAGE_CHANGED: 400019, // 此订单状态已更新，请刷新页面查看
  ORDER_TERMINATED: 400020, // 此订单已被终止，请刷新页面查看

  BALANCE_NOT_INSUFFICIENT: 400022, // 余额不足
  SETTLEMENT_STATUS_CHANGED: 400023, // 此结算单状态已被修改，请刷新页面查看
  ADJUSTMENT_STATUS_CHANGED: 400024, // 此调整单状态已被修改，请刷新页面查看
  EXCEEDED_DOWNLOAD_LIMIT: 400025, // 超过下载数量限制，请缩小下载范围
  BALANCE_CHANGED: 400026, // 该用户可用余额已被修改，请刷新页面查看
  CREDIT_VALID_DATE_OVERLAP: 400027, // 额度有效日期重叠
  INFO_CHANGED: 400028, // 信息已被修改，请刷新页面查看
  INVALID_OPERATION: 400029, // 无效操作: 信息已变更或无需更新信息或没有权限
  PRE_SALE_TASK_CHANGED: 400030, // 售前任务已被更新，请刷新后重试
  HAVE_TASK_WITHOUT_EFFECT_KOL: 400031, // 存在没有有效KOL的任务，无法提交
  SOCIAL_MEDIA_ALREADY_BIND_ERROR: 400032, // 社交媒体账号已被绑定
  ACTIVATION_LINK_INVALID_ERROR: 400033, // 该激活链接已失效
  INVALID_TAGS: 400034, // tagId列表异常，检查是否tagId数量大于10个、tagId重复以及tagId不合法
  INVALID_QUOTES: 400035, // cooperationTypes列表异常，检查是否合作类型重复、合作类型价格为0
  REPEATED_IM_INFOS: 400036, // 包含重复的IM账号
  ABNORMAL_MCN_APPLY_CODE: 400037, // MCN 申请码错误
  MCN_CHECKED: 400038, // MCN 已审核通过，请勿重复注册
  INACTIVE_MCN: 400039, // 未激活的MCN
  BAD_FILE_SIZE_10_M: 400040, // 仅支持上传10M以内的，请重新上传
  MCN_ADJUSTMENT_ATTACHMENT_IS_NULL: 400041, // MCN 调整单附件不能为空, 请确认
  TASK_NAME_INVALID: 400042, // 任务名称无效
  SHARE_NEXT_STATE: 400043, // 该订单已终止或进入下订阶段
  SHARE_ERROR_ORDER: 400044, // 该订单未生成链接
  BIND_MORE_THAN_THREE: 400046, //绑定社媒社媒超过三个
  INCORRECT_VERIFICATION_CODE: 400048, //绑定社交媒体的验证码错误
  INVALID_VERIFICATION_CODE: 400049, //绑定社交媒体的验证码无效
  AUTHORIZED_ACCOUNT_NOT_MATCH: 400050, // 收藏夹添加数量已达上限3000个，无法继续导入
  THIRDPARTY_AUTHORIZATION_FAILED: 400051, //tiktok帐号无视频，第三方授权失败
  RECORD_NOT_BELONG_CURRENT_USER: 400052, //该品牌分析记录不属于当前用户
  EXCEED_RECORD_LIMIT: 400053, //品牌分析记录数量已达10条，无法继续分析
  UNKNOWN_SEARCH_TYPE: 400054, //未知的品牌分析搜索类型
  RECORD_NOT_EXIST: 400055, //该品牌分析记录不存在或已被删除
  PAGE_NO_EXCEED_LIMIT: 400056, //页号超过最大页
  DELETE_UNFINISHED_RECORD: 400057, //试图删除未完成的品牌分析记录
  REPORT_SEARCH_TIMEOUT: 400058, //品牌分析报告明细查询超时
  PRINCIPAL_AM_VERIFY: 400059, //主运营已确认
  INACTIVE_ADVERTISER: 400061, // 未激活的广告主
  LENGTH_LIMIT_EXCEEDED: 400062, // 超出长度限制
  TASK_NOT_ONLINE: 400063, //任务未上线
  TASK_INFO_CHANGED: 400064, //任务信息已变更，请刷新重试
  LINK_EXPIRED: 400083, //链接已过期
  /**
   * 无权限请求系列异常 403
   */
  AUTHENTICATION_EXPIRED: 403001, // token已过期或未登录
  HTTP_403_AUTHENTICATION_FAILED: 403002, // 认证失败
  TASK_AUTHORITY: 403004, // 用户无权查看该任务
  INCOMPLETE_USER_INFO: 403005, // 用户个人资料未完善,无权获取任务
  AUTHENTICATION_INSUFFICIENT: 403006, // 当前用户权限不足
  AUTHENTICATION_INSUFFICIENT_IN_COMMIT: 403007, // 您没有提交权限
  AUTHENTICATION_INSUFFICIENT_IN_READ: 403008, // 当前用户无对应读权限
  INCOMPLETE_GDPR_INFO: 403009, // 当前用户GDPR信息未完善
  UNBOUND_KOL: 403010, // 当前用户未绑定KOL
  MCN_UNCHECKED: 403011, // MCN审核未通过或未提交, 请重新提交
  MCN_PENDING_AUDIT: 403012, // MCN 待审核
  UNBOUND_MCN: 403013, // 当前用户未绑定MCN
  AUTHENTICATION_CHANGE: 403014, //用户权限变更
  EMAIL_TEMPLATE_UNABLE_CHANGE: 403015, //非个人创建无法删除修改此公用模板
  /**
   * 非服务器资源系列异常 404
   */
  NOT_FOUND_COUNTRY_FILE: 404001, // 国家文件未找到

  NOT_FOUND_MEDIA: 404010, // 找不到素材
  NOT_FOUND_ORDER_SN: 404011, // 订单号不存在
  NOT_FOUND_INTERMEDIARY: 404012, // 媒介用户不存在
  NOT_FOUND_AM: 404013, // 运营用户不存在
  NOT_FOUND_APP_ID: 404014, // APP_ID 不存在
  NOT_FOUND_RANKING_DATE: 404015, // 排行日期不存在
  NOT_FOUND_COMPANY: 404016, // 广告主客户不存在
  NOT_FOUND_COMPANY_PAYMENT_TYPE: 404017, // 广告主客户付费类型不存在
  NOT_FOUND_COMPANY_ACCOUNT: 404018, // 广告主账户不存在
  NOT_FOUND_SETTLEMENT: 404019, // 结算单不存在
  NOT_FOUND_ADJUSTMENT: 404020, // 调整单不存在
  NOT_FOUND_KOL_ACCOUNT: 404021, // KOL 账户不存在
  NOT_FOUND_CREDIT_CHANGE: 404022, // 信用额度变更不存在或已过期，请刷新重试
  NOT_FOUND_CREDIT_LINE: 404023, // 信用额度不存在
  NOT_FOUND_FAVORITE: 404024, // 收藏夹不存在
  NOT_FOUND_WITHDRAW_SN: 404025, // 提现单不存在
  NOT_FOUND_BANK: 404026, // bank account is not exist
  NOT_FOUND_PAYPAL: 404027, // paypal account is not exist
  NOT_FOUND_UNIT_ID: 404028, // unitId is not exist
  NOT_FOUND_MCN_TS_ID: 404029, // mcnTsId is not exist
  NOT_FOUND_MCN: 404030, // mcn is not exist
  BIND_UNKNOWN_KOL: 404031, // 绑定kol不在库中
  NOT_FOUND_SOCIAL_MEDIA_ACCOUNT: 404032, // 社媒账号不存在
  NOT_FOUND_SOCIAL_MEDIA_BOUND_RECORD: 404033, // 未找到社媒账号绑定记录
  /**
   * 请求不允许系列异常 405
   */
  METHOD_NOT_ALLOWED: 405000, // general method not allowed
  NOT_ALLOWED_TOO_MANY_WITHDRAWAL: 405001, // 提现操作过于频繁
  NOT_ALLOWED_TOO_MANY_EMAIL_SENT: 405002, // 邮件发送过于频繁
  /**
   * 资源冲突系列异常
   */
  CONFLICT: 409000, // 冲突异常
  CONFLICT_EMAIL: 409001, // 该邮箱已被用于注册
  CONFLICT_NICKNAME: 409002, // 同一社交媒体下，有相同昵称
  CONFLICT_HOMEPAGE: 409003, // 同一社交媒体下，主页已经被注册
  CONFLICT_CUSTOMER_COMPANY_URL: 409004, // 此公司链接在平台内已被注册
  CONFLICT_CUSTOMER_PRODUCT: 409005, // 请输入不重复的产品名称
  CONFLICT_MCN: 409006, // 此MCN 名已被占用
  CONFLICT_NAME: 409007, // 该用户名已被用于注册
  KOL_URL_INVALID: 409008, // 该kol链接格式不合法
  KOL_URL_EXISTED: 409009, // 该kol链接已存在
  CONFLICT_CASH_FLOW: 409010, // 资金流水已存在
  CONFLICT_ADJUSTMENT_CHECKING: 409011, // 存在未结算调整单，无法新建
  CONFLICT_AUDIT: 409012, // 请勿重复审核
  CONFLICT_FAVORITE_NAME: 409013, // 收藏夹名称重复
  CONFLICT_FAVORITE_COUNT_LIMIT: 409014, // 收藏夹个数已达上限
  AIRWALLEX_ACCOUNT_EXISTED: 409015, // This account has been banded already
  AIRWALLEX_ACCOUNT_EXCEEDED_LIMIT: 409016, // No more than 5 accounts can be bounded. Please remove first and add a new one
  MCN_REGISTERED: 409017, // 匹配MCN已被绑定，请确认
  MCN_REPEAT_REGISTER: 409018, // 待审核中，请勿重复申请
  TASK_NAME_REPEAT: 409019, // 任务名称重复
  SHARE_FAVORITE_COUNT_LIMIT: 409020, // 收藏夹的共享人数量已达上限
  SHORT_URL_BIND_ORDER_LIMIT: 409021, // 短链绑定订单数已达上限
  ADVERTISER_FILL_INFO_REPEAT: 409022, // 广告主完善信息重复提交
  ADVERTISER_WILLING_REPEAT: 409023, // 广告主使用意愿重复提交
  GET_CONTACT_MORE_TIMES_LIMIT: 409024, //广告主获取达人联系方式超30次
  CAMPAIGN_NAME_REPEAT: 409027, // 推广系列名称重复
  HTTP_409_INSUFFICIENT_BALANCE: 409037, //余额不足，无法新增推广系列
  ALREADY_REFUSED_TO_COOPERATE: 409050, //已拒绝合作
  /**
   * 服务器内部错误系列异常 500
   */
  UNKNOWN_PERSONA_KEY_ERROR: 500006, // 未知的人群画像标识
};
export const REPORT_CODE = {
  // 400
  BAD_REQUEST: 400000, // 请求参数异常
  PASSWORD_CAN_NOT_BE_DECRYPTED: 400015, // 密码不合法，不能被服务端解密
  WITHDRAWAL_ACCOUNT_INVALID: 400016, // 用于提现的收款账户错误，请修改账户后再发起提现
  WITHDRAWAL_TYPE_INVALID: 400017, // 当前提现渠道不支持发起提现
  CONVERT_DATA_ERROR: 400021, // 转换数据格式出错
  // 403
  FORBiDDEN: 403000, // general forbidden
  AUTHENTICATION_FAILED: 403002, //认证失败
  // 404
  NOT_FOUND: 404000, // general resource not found
  NOT_FOUND_USER: 404002, // 该用户找不到
  NOT_FOUND_BILLING_HISTORY: 404003, // 找不到结算记录
  NOT_FOUND_KOL_ASSET: 404004, // 找不到该KOL的资产信息
  NOT_FOUND_TASK: 404005, // 找不到任务
  NOT_FOUND_ORDER_REACH: 404006, // 404007
  NOT_FOUND_ORDER_PRE_SALE: 404007, // 找不到售前订单
  NOT_FOUND_ORDER_CONFIRM: 404008, // 找不到下订订单
  NOT_FOUND_ORDER_ONLINE: 404009, // 找不到上线订单
  // 500
  SERVER_ERROR: 50000, // general server exception
  UPLOAD_TO_CDN_ERROR: 500001, // 上传素材至CDN失败
  YOUTUBE_QUERY_ERROR: 500002, // 查询 yutube 数据错误
  READ_CSV_FILE_FAILED: 500003, // 读取CSV文件出错
  SAVE_MEDIA_FAILED: 500004, // 保存素材出错，请稍后重试
  QUERY_ES_FAILED: 500005, // 查询请求出错
  SEND_EMAIL_ERROR: 500007, // 发送邮件失败
  READ_FILE_ERROR: 500008, // 读取文件失败
  POST_AIRWALLEX_ERROR: 500009, // 访问 Airwallex 失败
  ACQUIRE_LOCK_FAILED: 500010, // acquire lock fail
  SERVICE_503: 503, // Service Unavailable
  SERVICE_UNAVAILABLE: 503000, // Service Unavailable
  SYSTEM_MAINTENANCE: 503001, // SYSTEM MAINTENANCE
};

export const NotNeedToReportSentryErrorCodeOBJ = keyToValue(NOT_REPORT_CODE);

export const ErrorCode = {
  ...NOT_REPORT_CODE,
  ...REPORT_CODE,
};
