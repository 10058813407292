import { UserRole } from '@/constants/common';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  collapsed: boolean;
  identity?: 'ADVERTISER' | 'KOL' | null;
  unreadCount: number;
  notificationModalVisible: boolean;
}

const initialState: UserState = {
  collapsed: false,
  identity: null,
  unreadCount: 0,
  notificationModalVisible: false,
};
/**
 * 用来保存账号的登录态
 */
export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    changeCollapsed(state, { payload }: PayloadAction<boolean>) {
      state.collapsed = payload;
    },
    changeIdentity(state, { payload }: PayloadAction<'ADVERTISER' | 'KOL' | null>) {
      state.identity = payload;
    },
    changeUnreadCount(state, { payload }: PayloadAction<number>) {
      state.unreadCount = payload;
    },
    changeNotificationModalVisible(state, { payload }: PayloadAction<boolean>) {
      state.notificationModalVisible = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeCollapsed, changeIdentity, changeUnreadCount, changeNotificationModalVisible } = commonSlice.actions;

export default commonSlice.reducer;
