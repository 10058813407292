export const FacebookOutlineIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M22 12C22 6.47715 17.5229 2 12 2C6.47715 2 2 6.47715 2 12C2 16.9913 5.65685 21.1283 10.4375 21.8785V14.8906H7.89844V12H10.4375V9.79688C10.4375 7.29062 11.9304 5.90626 14.2146 5.90626C15.3087 5.90626 16.4531 6.10156 16.4531 6.10156V8.5625H15.1922C13.9499 8.5625 13.5625 9.33335 13.5625 10.1242V12H16.3359L15.8926 14.8906H13.5625V21.8785C18.3432 21.1283 22 16.9913 22 12Z"
        fill="#1877F2"
      />
      <path
        d="M15.8926 14.8906L16.3359 12H13.5625V10.1242C13.5625 9.33334 13.9499 8.5625 15.1921 8.5625H16.4531V6.10156C16.4531 6.10156 15.3087 5.90625 14.2146 5.90625C11.9304 5.90625 10.4375 7.29062 10.4375 9.79688V12H7.89844V14.8906H10.4375V21.8785C10.9544 21.9595 11.4768 22.0001 12 22C12.5316 22 13.0534 21.9584 13.5625 21.8785V14.8906H15.8926Z"
        fill="white"
      />
    </svg>
  );
};

export const FacebookGrayIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M10.3342 22.5371L11.1042 22.658V21.8785V14.8906V14.224H10.4375H8.56511V12.6667H10.4375H11.1042V12V9.79688C11.1042 8.67679 11.4351 7.88945 11.9411 7.38291C12.4479 6.87569 13.2072 6.57292 14.2146 6.57292C14.7262 6.57292 15.258 6.61886 15.6677 6.66618C15.7087 6.67091 15.7483 6.67564 15.7865 6.68032V7.89584H15.1922C14.4262 7.89584 13.8148 8.13943 13.4072 8.61071C13.0153 9.06393 12.8958 9.63186 12.8958 10.1242V12V12.6667H13.5625H15.5592L15.3204 14.224H13.5625H12.8958V14.8906V21.8785V22.658L13.6658 22.5371C18.7657 21.7368 22.6667 17.3247 22.6667 12C22.6667 6.10896 17.891 1.33333 12 1.33333C6.10896 1.33333 1.33333 6.10896 1.33333 12C1.33333 17.3247 5.23425 21.7368 10.3342 22.5371Z"
        fill="#9DA4AE"
        fillOpacity="0.3"
        stroke="white"
        strokeWidth="1.33333"
      />
      <path
        d="M15.8926 14.8906L16.3359 12H13.5625V10.1242C13.5625 9.33334 13.9499 8.5625 15.1921 8.5625H16.4531V6.10156C16.4531 6.10156 15.3087 5.90625 14.2146 5.90625C11.9304 5.90625 10.4375 7.29062 10.4375 9.79688V12H7.89844V14.8906H10.4375V21.8785C10.9544 21.9595 11.4768 22.0001 12 22C12.5316 22 13.0534 21.9584 13.5625 21.8785V14.8906H15.8926Z"
        fill="white"
      />
    </svg>
  );
};

export const FacebookIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
  >
    <path
      d="M12.8128 28.9919L13.5834 29.1135V28.3334V18.9592V18.2926H12.9167H10.198V15.7482H12.9167H13.5834V15.0815V12.1261C13.5834 10.5769 14.0394 9.45692 14.7658 8.72541C15.4924 7.99374 16.566 7.57352 17.9529 7.57352C18.6466 7.57352 19.3647 7.6361 19.9154 7.70007C20.0447 7.71509 20.1641 7.73011 20.2709 7.74427V9.80349H19.2563C18.282 9.80349 17.5343 10.1138 17.0431 10.6853C16.568 11.2381 16.4167 11.9387 16.4167 12.5652V15.0815V15.7482H17.0834H20.0053L19.6174 18.2926H17.0834H16.4167V18.9592V28.3334V29.1135L17.1874 28.9919C23.8835 27.9347 29.0001 22.1075 29.0001 15.0815C29.0001 7.3084 22.7358 1.00002 15.0001 1.00002C7.26432 1.00002 1.00008 7.30829 1.00008 15.0815C1.00008 22.1074 6.11669 27.9347 12.8128 28.9919Z"
      fill="#1877F2"
      stroke="white"
      strokeWidth="1.33333"
    />
    <path
      d="M20.1903 18.8542L20.7815 15.0001H17.0836V12.499C17.0836 11.4444 17.6001 10.4167 19.2565 10.4167H20.9377V7.13548C20.9377 7.13548 19.4119 6.87506 17.9531 6.87506C14.9075 6.87506 12.9169 8.72089 12.9169 12.0626V15.0001H9.53149V18.8542H12.9169V28.1714C13.6061 28.2794 14.3026 28.3336 15.0002 28.3334C15.6978 28.3336 16.3944 28.2794 17.0836 28.1714V18.8542H20.1903Z"
      fill="white"
    />
  </svg>
);
