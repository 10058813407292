import { UserRole } from '@/constants/common';
import { Locales } from '@/hooks/useLocal';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
export enum UserStatus {
  /** 注册未激活 */
  UNREGISTERED = 1,
  /** 注册已激活未完善资料 */
  REGISTERED,
  /** 注册已激活且已完善资料 */
  NORMAL,
}

interface UserState {
  isLoggedIn: boolean;
  email: string | null;
  id: number | null;
  role: UserRole | null;
  status: UserStatus | null;
  authToken: string | null;
  locale: Locales;
}

const initialState: UserState = {
  isLoggedIn: false,
  email: null,
  id: null,
  role: null,
  status: null,
  authToken: null,
  locale: 'en-US', // zh-cn/en
};
/**
 * 用来保存账号的登录态
 */
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginUser: (state, { payload }: PayloadAction<any>) => {
      const { email, userId, userType, status, authorization } = payload;
      state.isLoggedIn = true;
      state.email = email;
      state.id = userId;
      state.role = userType;
      state.status = status;
      state.authToken = authorization;
    },
    logoutUser: (state) => {
      state = {
        isLoggedIn: false,
        email: null,
        id: null,
        role: null,
        status: null,
        authToken: null,
        locale: state.locale,
      };
    },
    changeStatus(state, { payload }: PayloadAction<UserStatus>) {
      state.status = payload;
    },
    setLocal(state, { payload }: PayloadAction<Locales>) {
      state.locale = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { loginUser, logoutUser, changeStatus, setLocal } =
  userSlice.actions;

export default userSlice.reducer;
