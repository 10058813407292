import { tuple } from '@/utils/typeHelpers';
export const statuses = tuple('SUCCESS_PENDING', 'SUCCESS_ACTIVED');

export type Status = (typeof statuses)[number];

export const Statuses: Record<Status, Status> = {
  SUCCESS_PENDING: 'SUCCESS_PENDING',
  SUCCESS_ACTIVED: 'SUCCESS_ACTIVED',
};

export enum UserStatus {
  /** 注册未激活 */
  UNREGISTERED = 1,
  /** 注册已激活未完善资料 */
  REGISTERED,
  /** 注册已激活且已完善资料 */
  NORMAL,
}
