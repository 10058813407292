import Cookies from 'js-cookie';
import { LoginResult } from '@/services/login';
import { isUndefined } from './typeHelpers';

export const setAuthCookies = ({ email, userId, userType, status, authorization }: Partial<LoginResult>) => {
  const expireTime = new Date(new Date().getTime() + 10 * 60 * 60 * 1000); // 过期时间10小时
  !isUndefined(email) &&
    Cookies.set('USER_EMAIL', email, {
      expires: expireTime,
    });
  !isUndefined(userId) &&
    Cookies.set('USER_ID', userId.toString(), {
      expires: expireTime,
    });
  !isUndefined(userType) &&
    Cookies.set('USER_TYPE', userType.toString(), {
      expires: expireTime,
    });
  !isUndefined(status) &&
    Cookies.set('USER_STATUS', status.toString(), {
      expires: expireTime,
    });
  !isUndefined(authorization) &&
    Cookies.set('AUTH_TOKEN', authorization, {
      expires: expireTime,
    });
};

export const removeAuthCookies = () => {
  Cookies.remove('USER_EMAIL');
  Cookies.remove('USER_ID');
  Cookies.remove('USER_TYPE');
  Cookies.remove('USER_STATUS');
  Cookies.remove('AUTH_TOKEN');
};
