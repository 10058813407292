/*
 * @Date: 2024-01-19 19:37:14
 * @LastEditors: wangkai wangkai04@rd.netease.com
 * @LastEditTime: 2024-01-26 20:09:47
 * @Description: 消息通知常量
 */

export enum NotificationType {
  // 通知类型：1开头属于广告主
  KocAcceptOrder = 101, // !101-达人接单
  KocScriptSubmission = 102, //! 102-达人脚本提交
  KocFirstDraftSubmission = 103, // 103-达人初稿提交
  KocFinalDraftSubmission = 104, // 104-达人终稿提交
  KocMessage = 105, //! 105-达人留言
  KocOnline = 106, //! 106-达人上线

  // 通知类型：2开头属于KOC
  KocReceivedOrderInvitation = 201, //! 201-KOC收到接单邀请
  AdvertiserConfirmation = 202, //! 202-广告主确认合作
  ScriptConfirmation = 203, //! 203-脚本确认
  FirstDraftConfirmation = 204, //! 204-初稿确认
  FinalDraftConfirmation = 205, //! 205-终稿确认
  AdvertiserMessage = 206, //!  206-广告主留言
  PendingSettlement = 207, //! 207-待结算 (已到达结算周期)
  Settled = 208, // 208-已结算
}
