export const TwitterOutlineIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17.9636 3H20.8771L14.512 10.2434L22 20.1H16.137L11.5448 14.122L6.29041 20.1H3.37519L10.1832 12.3524L3 3H9.01187L13.1628 8.46411L17.9636 3ZM16.9411 18.3637H18.5555L8.13466 4.64511H6.40226L16.9411 18.3637Z"
        fill="black"
      />
    </svg>
  );
};

export const TwitterGrayIcon = () => {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="30" height="30" rx="15" fill="#B7B9BF" />
      <path
        d="M21.1761 8H23.9362L17.9061 14.7774L25 24H19.4456L15.0951 18.4066L10.1172 24H7.35544L13.8052 16.7508L7 8H12.6955L16.6279 13.1126L21.1761 8ZM20.2073 22.3754H21.7368L11.8644 9.53928H10.2232L20.2073 22.3754Z"
        fill="white"
      />
    </svg>
  );
};

export const TwitterIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.00008"
      y="1.00002"
      width="28"
      height="28"
      rx="14"
      fill="#111927"
      stroke="white"
      strokeWidth="1.33333"
    />
    <g clipPath="url(#clip0_241_7635)">
      <path
        d="M19.6012 7.88892H22.0546L16.6946 13.9133L23.0002 22.1111H18.063L14.1959 17.1392L9.77111 22.1111H7.31619L13.0493 15.6674L7.00024 7.88892H12.0629L15.5583 12.4335L19.6012 7.88892ZM18.7401 20.667H20.0996L11.3242 9.25717H9.86531L18.7401 20.667Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_241_7635">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(7.00024 7.00006)"
        />
      </clipPath>
    </defs>
  </svg>
);
