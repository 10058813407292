export const TwitchOutlineIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M6.57143 2L3 5.57143V18.4286H7.28571V22L10.8571 18.4286H13.7143L20.1429 12V2H6.57143Z"
        fill="#8651F5"
      />
      <path
        d="M15.8571 14.1429L18.7142 11.2857V3.42859H7.28564V14.1429H10.1428V17L12.9999 14.1429H15.8571Z"
        fill="white"
      />
      <path
        d="M11.5718 6.28564H13.0003V10.5714H11.5718V6.28564ZM16.5718 6.28564V10.5714H15.1432V6.28564H16.5718Z"
        fill="#8751F6"
      />
    </svg>
  );
};

export const TwitchGrayIcon = () => {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="30" height="30" rx="15" fill="#B7B9BF" />
      <g clipPath="url(#clip0_320_1851)">
        <path
          d="M21.0218 11.3407H19.5879V15.4127H21.0218V11.3407ZM17.0809 11.3233H15.647V15.3971H17.0809V11.3233ZM10.987 7.5L7.4043 10.8934V23.1116H11.7038V26.505L15.2869 23.1116H18.1544L24.6046 17.0025V7.5H10.987ZM23.171 16.3251L20.3047 19.0392H17.4376L14.9283 21.4149V19.0392H11.7038V8.85782H23.171V16.3251Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_320_1851">
          <rect
            width="19.005"
            height="19.005"
            fill="white"
            transform="translate(6.50195 7.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const TwitchIcon = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="ic">
      <rect
        id="image 83"
        x="1.00008"
        y="1.00002"
        width="28"
        height="28"
        rx="14"
        fill="#9146FF"
        stroke="white"
        strokeWidth="1.33333"
      />
      <g id="twitch 1" clipPath="url(#clip0_241_7622)">
        <path
          id="Vector"
          d="M19.4638 10.8586H18.1893V14.4781H19.4638V10.8586ZM15.9608 10.843H14.6862V14.4642H15.9608V10.843ZM10.544 7.44458L7.35938 10.461V21.3215H11.1812V24.3379L14.3661 21.3215H16.915L22.6485 15.8912V7.44458H10.544ZM21.3742 15.2891L18.8264 17.7017H16.2779L14.0474 19.8133V17.7017H11.1812V8.65153H21.3742V15.2891Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_241_7622">
        <rect
          width="16.8933"
          height="16.8933"
          fill="white"
          transform="translate(6.55737 7.44458)"
        />
      </clipPath>
    </defs>
  </svg>
);
