import { getDate } from '@/utils/util';

export const CREATE_PROMOTION_KEY = 'KOC_CREATE_PROMOTION';
export const ADD_RESOURCE_DIRECT_KEY = 'KOC_ADD_DIRECT_RESOURCE';
export const ADD_RESOURCE_CODE_KEY = 'KOC_ADD_CODE_RESOURCE';
export const WITHDRAW_KEY = 'KOC_WITHDRAW';

export const gaEventName = {
  PAGE_VIEW: 'page_view',
  UNIQUE_VISITOR: 'unique_visitor',
  CLICK: 'click',
  CREATE_PROMOTION: 'create_promotion',
  ADD_RESOURCE: 'add_resource',
  WITHDRAW: 'withdraw',
  SIGN_UP_CLICK: 'sign_up_click',
  EMAIL_CONTENT: 'email_content',
  PAGE_STAY: 'page_stay',
  OFFER_SIGN_UP: 'offer_sign_up',
} as const;

type ValueOf<T> = T[keyof T];
type GaEventName = ValueOf<typeof gaEventName>;

export const gtagHasLoaded = () => {
  return Boolean(document.getElementById('gtag-init'));
};

interface addGtagEventProps {
  event: GaEventName;
  options?: any;
  uvId?: string;
}
export const objectToArray = (obj: any = {}) => {
  const arr: [string, string | number | null][] = [];
  Object.keys(obj).forEach((key) => {
    obj[key] && arr.push(['ex_' + key, obj[key]]);
  });
  return arr;
};

export const addRlog = ({ event, options }: any) => {
  if (typeof window !== 'undefined' && window._rlog) {
    //处理pv
    if (event === 'page_view') {
      Object.keys(options).forEach((k) => options[k] && window._rlog.push(['_addPost', 'ex_' + k, options[k]]));
      window._rlog.push(['_trackPageview', 'ido']);
      Object.keys(options).forEach((k) => options[k] && window._rlog.push(['_removePost', 'ex_' + k]));
      return;
    }
    //处理click事件
    if (event === 'click' && options?.click_id) {
      event = options.click_id;
      delete options.click_id;
    }
    if (options) {
      window._rlog.push(['_trackCustom', 'EVENT', [['event_id', event], ...objectToArray(options)]]);
    } else {
      window._rlog.push(['_trackEvent', event]);
    }
  }
};
export function addGtagEvent({ event, options = {} }: addGtagEventProps) {
  if (!gtagHasLoaded()) return;

  addRlog({ event, options });
  gtag('event', event, options);
}
