// theme/themeConfig.ts
import type { ThemeConfig } from 'antd';

const theme: ThemeConfig = {
  token: {
    fontSize: 14,
    fontFamily: `'HarmonyOS Sans SC',-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji';`,
    screenXXLMin: 1920, // for grid (row/col)
    screenXXL: 1920, // default is 1600, for List
    screenXL: 1328, // default is 1200, for List
    screenXLMin: 1328, // for grid (row/col)
    screenXLMax: 1919,
    screenSM: 548,
    screenSMMin: 548,
    screenXS: 390,
    screenXSMin: 390,
    screenXSMax: 547,
    colorPrimary: '#FF4E16',
    colorPrimaryText: '#1c1c1c',
    colorPrimaryBg: '#FFF0E6',
    colorPrimaryHover: '#FF7340', //主要悬浮色
    colorPrimaryActive: '#D93209', //主要点击色
    // colorBgContainerDisabled: '#FFB691', //禁用色
    // colorTextDisabled: '#B6BCC7', //禁用文字色
    colorSuccess: '#23D011', //成功常规色
    colorSuccessBg: '#ECFFE6', //成功背景色
    colorWarning: '#FC850A', //提醒色
    colorWarningBg: '#FFF7E6', //提醒背景色
    // colorTextQuaternary: '#D9DEE5', //提示文字色
    colorError: '#FF4244', //警告色
    colorErrorBg: '#FFF2F0', //警告背景色
    colorInfo: '#315EFF',
    colorInfoBgHover: '#5983FF',
    colorInfoActive: '#1E41D9',
    colorInfoBg: '#F0F6FF',
    colorText: '#2D3036',
    colorTextSecondary: '#808591',
    // colorFill: '#D9DEE5', //一级填充色
    colorFillSecondary: '#ECEFF4', //二级填充色
    colorFillTertiary: '#F6F6FA', //三级填充色
    colorBorder: '#D9DEE5',
    colorBorderSecondary: '#ECEFF4',
  },
};

export default theme;
