import { NotificationType } from '.';

const InvitationIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g filter="url(#filter0_ii_2613_12413)">
      <path
        d="M2 4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V20C22 20.5523 21.5523 21 21 21H3C2.44772 21 2 20.5523 2 20V4Z"
        fill="#FF4E16"
      />
    </g>
    <path d="M12.0604 11.6829L5.64698 6.2377L4.35254 7.7623L12.0729 14.3171L19.6542 7.75616L18.3454 6.24384L12.0604 11.6829Z" fill="white" />
    <g filter="url(#filter1_b_2613_12413)">
      <circle cx="18.5" cy="18.5" r="4.5" fill="url(#paint0_radial_2613_12413)" fillOpacity="0.2" />
      <circle cx="18.5" cy="18.5" r="4.5" stroke="white" strokeWidth="0.25" />
    </g>
    <g filter="url(#filter2_d_2613_12413)">
      <path
        d="M16.3296 18.5051L18.0797 20.2551C18.1334 20.3088 18.2205 20.3088 18.2742 20.2551L20.8021 17.7272C20.8558 17.6736 20.8558 17.5865 20.8021 17.5328L20.2187 16.9494C20.165 16.8957 20.0779 16.8957 20.0242 16.9494L18.2742 18.6995C18.2205 18.7532 18.1334 18.7532 18.0797 18.6995L17.1075 17.7273C17.0538 17.6736 16.9667 17.6736 16.913 17.7273L16.3296 18.3106C16.276 18.3643 16.276 18.4514 16.3296 18.5051Z"
        fill="white"
      />
    </g>
    <defs>
      <filter id="filter0_ii_2613_12413" x="1" y="2" width="22" height="20" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="-1" dy="-1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.988235 0 0 0 0 0.521569 0 0 0 0 0.0392157 0 0 0 0.8 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2613_12413" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="1" dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.988235 0 0 0 0 0.521569 0 0 0 0 0.0392157 0 0 0 0.8 0" />
        <feBlend mode="normal" in2="effect1_innerShadow_2613_12413" result="effect2_innerShadow_2613_12413" />
      </filter>
      <filter
        id="filter1_b_2613_12413"
        x="9.875"
        y="9.875"
        width="17.25"
        height="17.25"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2613_12413" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2613_12413" result="shape" />
      </filter>
      <filter
        id="filter2_d_2613_12413"
        x="15.2891"
        y="16.1591"
        width="6.55371"
        height="5.38628"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="0.25" />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.305882 0 0 0 0 0.0862745 0 0 0 0.1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2613_12413" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2613_12413" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_2613_12413"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.5 18.5) rotate(90) scale(4.5)"
      >
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset="1" stopColor="#FFCB00" />
      </radialGradient>
    </defs>
  </svg>
);

const ConfirmIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6953 14.5731C18.5268 13.0693 19 11.3399 19 9.5C19 8.08254 18.7191 6.73068 18.21 5.49704L14.5994 3.1994C13.9312 2.77422 13.0752 2.78333 12.4162 3.22262L7.01517 6.82332C6.49011 7.17336 6.41655 7.91626 6.86277 8.36247L7.15553 8.65524C7.6758 9.17551 8.43971 9.3681 9.14444 9.15668L11.6297 8.41109C12.4493 8.16522 13.3345 8.46797 13.8319 9.16427L17.6953 14.5731ZM16.8101 15.919L12.6109 10.0361C12.4865 9.86205 12.2652 9.78636 12.0603 9.84783L9.57505 10.5934C8.34176 10.9634 7.00492 10.6264 6.09446 9.7159L5.80169 9.42313C4.68615 8.30759 4.87005 6.45035 6.18271 5.57524L7.04557 5H3C2.44772 5 2 5.44772 2 6V13.5158C2 13.9242 2.12475 14.3173 2.35019 14.6464C2.3741 14.6238 2.42357 14.5796 2.42357 14.5796L4.03933 13.1658C5.47457 11.91 7.65103 12.0343 8.93388 13.4455L11.6361 16.4179C12.3376 17.1896 12.6425 18.2116 12.5198 19.203C14.222 18.497 15.6983 17.3562 16.8101 15.919ZM10.7913 19.7493C11.2113 18.9993 11.1052 18.0639 10.5261 17.4269L7.82397 14.4545C7.09091 13.6481 5.84722 13.5771 5.02708 14.2947L3.41132 15.7085C2.93991 16.1209 2.95945 16.8603 3.45201 17.2474L6.77614 19.8592C7.33702 19.9518 7.91288 20 8.5 20C9.287 20 10.0538 19.9134 10.7913 19.7493Z"
      fill="#FF4E16"
    />
    <g filter="url(#filter0_b_2613_10756)">
      <path
        d="M19.2919 5.99994H20.0007C20.553 5.99994 21.0007 6.44766 21.0007 6.99994V13.9999C21.0007 14.5522 20.553 14.9999 20.0007 14.9999H18.5153C18.1924 14.9999 17.8893 14.8439 17.7016 14.5811L13.8324 9.16427C13.335 8.46797 12.4498 8.16522 11.6302 8.41109L9.14493 9.15668C8.4402 9.3681 7.67629 9.17551 7.15602 8.65524L6.86326 8.36247C6.41704 7.91626 6.4906 7.17336 7.01566 6.82332L12.4167 3.22262C13.0757 2.78333 13.9317 2.77422 14.5999 3.1994L18.7551 5.8436C18.9155 5.94571 19.1018 5.99994 19.2919 5.99994Z"
        fill="url(#paint0_radial_2613_10756)"
        fillOpacity="0.2"
      />
      <path
        d="M19.2919 5.99994H20.0007C20.553 5.99994 21.0007 6.44766 21.0007 6.99994V13.9999C21.0007 14.5522 20.553 14.9999 20.0007 14.9999H18.5153C18.1924 14.9999 17.8893 14.8439 17.7016 14.5811L13.8324 9.16427C13.335 8.46797 12.4498 8.16522 11.6302 8.41109L9.14493 9.15668C8.4402 9.3681 7.67629 9.17551 7.15602 8.65524L6.86326 8.36247C6.41704 7.91626 6.4906 7.17336 7.01566 6.82332L12.4167 3.22262C13.0757 2.78333 13.9317 2.77422 14.5999 3.1994L18.7551 5.8436C18.9155 5.94571 19.1018 5.99994 19.2919 5.99994Z"
        stroke="white"
        strokeWidth="0.25"
      />
    </g>
    <g filter="url(#filter1_b_2613_10756)">
      <path
        d="M5.02659 14.2947L3.41083 15.7085C2.93942 16.1209 2.95896 16.8603 3.45152 17.2474L8.59228 21.2865C9.07235 21.6637 9.77543 21.5264 10.0783 20.9963L10.7822 19.7645C11.2122 19.012 11.1086 18.0682 10.5256 17.4269L7.82348 14.4545C7.09042 13.6481 5.84673 13.5771 5.02659 14.2947Z"
        fill="url(#paint1_radial_2613_10756)"
        fillOpacity="0.2"
      />
      <path
        d="M5.02659 14.2947L3.41083 15.7085C2.93942 16.1209 2.95896 16.8603 3.45152 17.2474L8.59228 21.2865C9.07235 21.6637 9.77543 21.5264 10.0783 20.9963L10.7822 19.7645C11.2122 19.012 11.1086 18.0682 10.5256 17.4269L7.82348 14.4545C7.09042 13.6481 5.84673 13.5771 5.02659 14.2947Z"
        stroke="white"
        strokeWidth="0.25"
      />
    </g>
    <g filter="url(#filter2_b_2613_10756)">
      <path
        d="M7.04557 5H3C2.44772 5 2 5.44772 2 6V13.5158C2 13.9242 2.12475 14.3173 2.35019 14.6464C2.3741 14.6238 2.39856 14.6015 2.42357 14.5796L4.03933 13.1658C5.47457 11.91 7.65103 12.0343 8.93388 13.4455L11.6361 16.4179C12.6563 17.5401 12.8376 19.1918 12.0851 20.5087L11.4308 21.6538C11.9937 21.8671 12.635 21.819 13.169 21.4986L17.5782 18.8531C18.0786 18.5528 18.2166 17.8896 17.8776 17.4146L12.6109 10.0361C12.4865 9.86205 12.2652 9.78636 12.0603 9.84783L9.57505 10.5934C8.34176 10.9634 7.00492 10.6264 6.09446 9.7159L5.80169 9.42313C4.68615 8.30759 4.87005 6.45035 6.18271 5.57524L7.04557 5Z"
        fill="#FF4E16"
      />
      <path
        d="M7.04557 5H3C2.44772 5 2 5.44772 2 6V13.5158C2 13.9242 2.12475 14.3173 2.35019 14.6464C2.3741 14.6238 2.39856 14.6015 2.42357 14.5796L4.03933 13.1658C5.47457 11.91 7.65103 12.0343 8.93388 13.4455L11.6361 16.4179C12.6563 17.5401 12.8376 19.1918 12.0851 20.5087L11.4308 21.6538C11.9937 21.8671 12.635 21.819 13.169 21.4986L17.5782 18.8531C18.0786 18.5528 18.2166 17.8896 17.8776 17.4146L12.6109 10.0361C12.4865 9.86205 12.2652 9.78636 12.0603 9.84783L9.57505 10.5934C8.34176 10.9634 7.00492 10.6264 6.09446 9.7159L5.80169 9.42313C4.68615 8.30759 4.87005 6.45035 6.18271 5.57524L7.04557 5Z"
        fill="url(#paint2_radial_2613_10756)"
        fillOpacity="0.2"
      />
      <path
        d="M7.04557 5H3C2.44772 5 2 5.44772 2 6V13.5158C2 13.9242 2.12475 14.3173 2.35019 14.6464C2.3741 14.6238 2.39856 14.6015 2.42357 14.5796L4.03933 13.1658C5.47457 11.91 7.65103 12.0343 8.93388 13.4455L11.6361 16.4179C12.6563 17.5401 12.8376 19.1918 12.0851 20.5087L11.4308 21.6538C11.9937 21.8671 12.635 21.819 13.169 21.4986L17.5782 18.8531C18.0786 18.5528 18.2166 17.8896 17.8776 17.4146L12.6109 10.0361C12.4865 9.86205 12.2652 9.78636 12.0603 9.84783L9.57505 10.5934C8.34176 10.9634 7.00492 10.6264 6.09446 9.7159L5.80169 9.42313C4.68615 8.30759 4.87005 6.45035 6.18271 5.57524L7.04557 5Z"
        stroke="white"
        strokeWidth="0.25"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_2613_10756"
        x="2.44531"
        y="-1.23828"
        width="22.6807"
        height="20.3632"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2613_10756" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2613_10756" result="shape" />
      </filter>
      <filter
        id="filter1_b_2613_10756"
        x="0.944336"
        y="11.6748"
        width="12.2266"
        height="11.9505"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2613_10756" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2613_10756" result="shape" />
      </filter>
      <filter
        id="filter2_b_2613_10756"
        x="-2.125"
        y="0.875"
        width="24.3135"
        height="25.0336"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2613_10756" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2613_10756" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_2613_10756"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(13.7855 8.94331) rotate(90) scale(6.05659 7.21519)"
      >
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset="1" stopColor="#FFCB00" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_2613_10756"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(7.05755 17.6501) rotate(90) scale(3.85025 3.98821)"
      >
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset="1" stopColor="#FFCB00" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_2613_10756"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10.0319 13.3918) rotate(90) scale(8.3918 8.0319)"
      >
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset="1" stopColor="#FFCB00" />
      </radialGradient>
    </defs>
  </svg>
);

const SubmitIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g filter="url(#filter0_i_2619_14742)">
      <path
        d="M15.2446 3C15.5124 3 15.7689 3.10738 15.9569 3.29809L19.7113 7.10795C19.8956 7.29499 19.999 7.54703 19.999 7.80964L20 12.2343V20C20 20.5523 19.5547 21 19.0024 21C17.069 21 12.8407 21 11.0014 21C5.68942 21 4.89006 20.9995 4.88301 20.9995C4.88278 20.9995 4.88285 20.9995 4.88262 20.9995C4.43011 20.9993 4.05695 20.6547 4.00595 20.211L4 20.1069V3.89259C4 3.44425 4.34388 3.05891 4.78691 3.00615L4.89079 3H15.2446Z"
        fill="#FA4F03"
      />
    </g>
    <path
      d="M8.25 10H15.75C15.8881 10 16 9.88807 16 9.75V8.25C16 8.11193 15.8881 8 15.75 8H8.25C8.11193 8 8 8.11193 8 8.25V9.75C8 9.88807 8.11193 10 8.25 10Z"
      fill="white"
    />
    <path
      d="M8.25 14H12.75C12.8881 14 13 13.8881 13 13.75V12.25C13 12.1119 12.8881 12 12.75 12H8.25C8.11193 12 8 12.1119 8 12.25V13.75C8 13.8881 8.11193 14 8.25 14Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0012 17.52V21.0006C21.0012 21.5529 20.556 22.0006 20.0037 22.0006H12.6291C12.2361 21.525 12 20.9151 12 20.25C12 19.026 12.7997 17.9887 13.9052 17.6321C13.9625 17.6137 14.002 17.5602 14 17.5C14 15.567 15.567 14 17.5 14C19.433 14 21 15.567 21 17.5C20.9999 17.5068 21.0003 17.5134 21.0012 17.52Z"
      fill="#FF4E16"
    />
    <g filter="url(#filter1_b_2619_14742)">
      <path
        d="M17.5 14C19.433 14 21 15.567 21 17.5C20.9991 17.5609 21.0394 17.6145 21.0974 17.6332C22.2011 17.9898 23 19.0266 23 20.25C23 21.7125 21.8583 22.9084 20.4175 22.995L20.25 23H14.75L14.5825 22.995C13.1417 22.9084 12 21.7125 12 20.25C12 19.026 12.7997 17.9887 13.9052 17.6321C13.9625 17.6137 14.002 17.5602 14 17.5C14 15.567 15.567 14 17.5 14Z"
        fill="url(#paint0_radial_2619_14742)"
        fillOpacity="0.2"
      />
      <path
        d="M17.5 14C19.433 14 21 15.567 21 17.5C20.9991 17.5609 21.0394 17.6145 21.0974 17.6332C22.2011 17.9898 23 19.0266 23 20.25C23 21.7125 21.8583 22.9084 20.4175 22.995L20.25 23H14.75L14.5825 22.995C13.1417 22.9084 12 21.7125 12 20.25C12 19.026 12.7997 17.9887 13.9052 17.6321C13.9625 17.6137 14.002 17.5602 14 17.5C14 15.567 15.567 14 17.5 14Z"
        stroke="white"
        strokeWidth="0.25"
      />
    </g>
    <path
      d="M18 19.25C18 19.1119 18.1119 19 18.25 19H19.2586C19.3477 19 19.3923 18.8923 19.3293 18.8293L17.6768 17.1768C17.5791 17.0791 17.4209 17.0791 17.3232 17.1768L15.6707 18.8293C15.6077 18.8923 15.6523 19 15.7414 19H16.75C16.8881 19 17 19.1119 17 19.25V20.75C17 20.8881 17.1119 21 17.25 21H17.75C17.8881 21 18 20.8881 18 20.75V19.25Z"
      fill="white"
    />
    <defs>
      <filter id="filter0_i_2619_14742" x="4" y="3" width="16" height="18" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.54 0 0 0 0 0 0 0 0 1 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2619_14742" />
      </filter>
      <filter
        id="filter1_b_2619_14742"
        x="7.875"
        y="9.875"
        width="19.25"
        height="17.2501"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2619_14742" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2619_14742" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_2619_14742"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.5 18.5) rotate(90) scale(4.5 5.5)"
      >
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset="1" stopColor="#FFCB00" />
      </radialGradient>
    </defs>
  </svg>
);

const ReplyIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g filter="url(#filter0_i_2619_12827)">
      <path
        d="M2 4C2 3.44772 2.44772 3 3 3H17C17.5523 3 18 3.44772 18 4V14C18 14.5523 17.5523 15 17 15H8.91421C8.649 15 8.39464 15.1054 8.20711 15.2929L7.20711 16.2929C6.81658 16.6834 6.18342 16.6834 5.79289 16.2929L4.79289 15.2929C4.60536 15.1054 4.351 15 4.08579 15H3C2.44772 15 2 14.5523 2 14V4Z"
        fill="#FA4F03"
      />
    </g>
    <path fillRule="evenodd" clipRule="evenodd" d="M20 11V16C20 16.5523 19.5523 17 19 17H11V12C11 11.4477 11.4477 11 12 11H18H20Z" fill="#FF4E16" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 11V15.522C20 16.0743 19.5523 16.522 19 16.522H11V12C11 11.4477 11.4477 11 12 11H18H20Z"
      fill="#FF4E16"
    />
    <g filter="url(#filter1_b_2619_12827)">
      <path
        d="M16.7929 19.2929C16.6054 19.1054 16.351 19 16.0858 19H12C11.4477 19 11 18.5523 11 18V12C11 11.4477 11.4477 11 12 11H18H21C21.5523 11 22 11.4477 22 12V18C22 18.5523 21.5523 19 21 19H19.9142C19.649 19 19.3946 19.1054 19.2071 19.2929L18.7071 19.7929C18.3166 20.1834 17.6834 20.1834 17.2929 19.7929L16.7929 19.2929Z"
        fill="url(#paint0_radial_2619_12827)"
        fillOpacity="0.2"
      />
      <path
        d="M16.7929 19.2929C16.6054 19.1054 16.351 19 16.0858 19H12C11.4477 19 11 18.5523 11 18V12C11 11.4477 11.4477 11 12 11H18H21C21.5523 11 22 11.4477 22 12V18C22 18.5523 21.5523 19 21 19H19.9142C19.649 19 19.3946 19.1054 19.2071 19.2929L18.7071 19.7929C18.3166 20.1834 17.6834 20.1834 17.2929 19.7929L16.7929 19.2929Z"
        stroke="white"
        strokeWidth="0.25"
      />
    </g>
    <path d="M14.5 15H15" stroke="white" strokeWidth="1.5" stroke-linecap="round" />
    <path d="M18 15H18.5" stroke="white" strokeWidth="1.5" stroke-linecap="round" />
    <path d="M10 8H10.5" stroke="white" strokeWidth="2" stroke-linecap="round" />
    <path d="M14 8H14.5" stroke="white" strokeWidth="2" stroke-linecap="round" />
    <path d="M6 8H6.5" stroke="white" strokeWidth="2" stroke-linecap="round" />
    <defs>
      <filter id="filter0_i_2619_12827" x="2" y="3" width="16" height="13.5858" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.54 0 0 0 0 0 0 0 0 1 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2619_12827" />
      </filter>
      <filter
        id="filter1_b_2619_12827"
        x="6.875"
        y="6.875"
        width="19.25"
        height="17.3358"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2619_12827" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2619_12827" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_2619_12827"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(16.5 15.75) rotate(90) scale(4.75 5.5)"
      >
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset="1" stopColor="#FFCB00" />
      </radialGradient>
    </defs>
  </svg>
);

const PeriodIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g filter="url(#filter0_i_2619_14195)">
      <circle cx="14" cy="10" r="8" fill="#FA4F03" />
    </g>
    <path
      d="M10.5714 11H9.42857V11.6C8.63959 11.6 8 12.2716 8 13.1C8 13.8953 8.58945 14.546 9.33464 14.5968L9.42857 14.6H10.5714L10.6228 14.6048C10.7561 14.6302 10.8571 14.7527 10.8571 14.9C10.8571 15.0473 10.7561 15.1698 10.6228 15.1952L10.5714 15.2H8.28571V16.4H9.42857V17H10.5714V16.4C11.3604 16.4 12 15.7285 12 14.9C12 14.1047 11.4105 13.454 10.6654 13.4032L10.5714 13.4H9.42857L9.37721 13.3952C9.24393 13.3698 9.14285 13.2473 9.14285 13.1C9.14285 12.9527 9.24393 12.8302 9.37721 12.8048L9.42857 12.8H11.7143V11.6H10.5714V11Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.07418 7.02787C7.23396 6.37343 8.57337 6 10 6C14.4183 6 18 9.58172 18 14C18 15.8791 17.3521 17.6069 16.2676 18.9721C15.1078 19.6266 13.7684 20 12.3418 20C7.92352 20 4.3418 16.4183 4.3418 12C4.3418 10.1209 4.98965 8.39315 6.07418 7.02787Z"
      fill="#FF4E16"
    />
    <g filter="url(#filter1_b_2619_14195)">
      <circle cx="10" cy="14" r="8" fill="url(#paint0_radial_2619_14195)" fillOpacity="0.2" />
      <circle cx="10" cy="14" r="8" stroke="white" strokeWidth="0.25" />
    </g>
    <path
      d="M10.5714 11H9.42857V11.6C8.63959 11.6 8 12.2716 8 13.1C8 13.8953 8.58945 14.546 9.33464 14.5968L9.42857 14.6H10.5714L10.6228 14.6048C10.7561 14.6302 10.8571 14.7527 10.8571 14.9C10.8571 15.0473 10.7561 15.1698 10.6228 15.1952L10.5714 15.2H8.28571V16.4H9.42857V17H10.5714V16.4C11.3604 16.4 12 15.7285 12 14.9C12 14.1047 11.4105 13.454 10.6654 13.4032L10.5714 13.4H9.42857L9.37721 13.3952C9.24393 13.3698 9.14285 13.2473 9.14285 13.1C9.14285 12.9527 9.24393 12.8302 9.37721 12.8048L9.42857 12.8H11.7143V11.6H10.5714V11Z"
      fill="white"
    />
    <defs>
      <filter id="filter0_i_2619_14195" x="6" y="2" width="16" height="16" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.54 0 0 0 0 0 0 0 0 1 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2619_14195" />
      </filter>
      <filter
        id="filter1_b_2619_14195"
        x="-2.125"
        y="1.875"
        width="24.25"
        height="24.25"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2619_14195" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2619_14195" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_2619_14195"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(10 14) rotate(90) scale(8)"
      >
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset="1" stopColor="#FFCB00" />
      </radialGradient>
    </defs>
  </svg>
);

const SettledIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M15.0049 12.7498V11.2498C15.0049 11.1117 15.1168 10.9998 15.2549 10.9998H17.7549C17.893 10.9998 18.0049 11.1117 18.0049 11.2498V12.7498C18.0049 12.8879 17.893 12.9998 17.7549 12.9998H15.2549C15.1168 12.9998 15.0049 12.8879 15.0049 12.7498Z"
      fill="#FFF0E6"
    />
    <g filter="url(#filter0_i_2619_13147)">
      <rect x="2" y="3" width="19" height="18" rx="1" fill="#FA4F03" />
    </g>
    <g filter="url(#filter1_b_2619_13147)">
      <path
        d="M22.0049 7.99976H15.0049C12.7957 7.99976 11.0049 9.79062 11.0049 11.9998C11.0049 14.2089 12.7957 15.9998 15.0049 15.9998H22.0049C22.5572 15.9998 23.0049 15.5521 23.0049 14.9998V8.99976C23.0049 8.44747 22.5572 7.99976 22.0049 7.99976Z"
        fill="url(#paint0_radial_2619_13147)"
        fillOpacity="0.2"
      />
      <path
        d="M22.0049 7.99976H15.0049C12.7957 7.99976 11.0049 9.79062 11.0049 11.9998C11.0049 14.2089 12.7957 15.9998 15.0049 15.9998H22.0049C22.5572 15.9998 23.0049 15.5521 23.0049 14.9998V8.99976C23.0049 8.44747 22.5572 7.99976 22.0049 7.99976Z"
        stroke="white"
        strokeWidth="0.25"
      />
    </g>
    <path
      d="M15.0049 12.7498V11.2498C15.0049 11.1117 15.1168 10.9998 15.2549 10.9998H17.7549C17.893 10.9998 18.0049 11.1117 18.0049 11.2498V12.7498C18.0049 12.8879 17.893 12.9998 17.7549 12.9998H15.2549C15.1168 12.9998 15.0049 12.8879 15.0049 12.7498Z"
      fill="white"
    />
    <defs>
      <filter id="filter0_i_2619_13147" x="2" y="3" width="19" height="18" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.54 0 0 0 0 0 0 0 0 1 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2619_13147" />
      </filter>
      <filter
        id="filter1_b_2619_13147"
        x="6.87988"
        y="3.87476"
        width="20.25"
        height="16.25"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_2619_13147" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_2619_13147" result="shape" />
      </filter>
      <radialGradient
        id="paint0_radial_2619_13147"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(17.0049 11.9998) rotate(90) scale(4 6)"
      >
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset="1" stopColor="#FFCB00" />
      </radialGradient>
    </defs>
  </svg>
);

const OnlineIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <g filter="url(#filter0_ii_3494_96161)">
      <path
        d="M13 18V20H16C16.5523 20 17 20.4477 17 21C17 21.5523 16.5523 22 16 22H8C7.44772 22 7 21.5523 7 21C7 20.4477 7.44772 20 8 20H11V18H3C2.44772 18 2 17.5523 2 17V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V17C22 17.5523 21.5523 18 21 18H13ZM10.7572 7.95435C10.424 7.75439 10 7.99445 10 8.3831V12.6169C10 13.0056 10.424 13.2456 10.7572 13.0457L14.2854 10.9287C14.6091 10.7345 14.6091 10.2655 14.2854 10.0713L10.7572 7.95435Z"
        fill="#FF4E16"
      />
    </g>
    <rect x="14" y="14" width="9" height="9" rx="3" fill="url(#paint0_linear_3494_96161)" />
    <g filter="url(#filter1_b_3494_96161)">
      <rect x="14" y="14" width="9" height="9" rx="3" fill="url(#paint1_radial_3494_96161)" fillOpacity="0.2" />
      <rect x="14" y="14" width="9" height="9" rx="3" stroke="white" strokeWidth="0.25" />
    </g>
    <path
      d="M17.5 19.5657V17.4343C17.5 17.0349 17.9451 16.7967 18.2773 17.0182L19.876 18.084C20.1728 18.2819 20.1728 18.7181 19.876 18.916L18.2774 19.9818C17.9451 20.2033 17.5 19.9651 17.5 19.5657Z"
      fill="white"
    />
    <defs>
      <filter id="filter0_ii_3494_96161" x="1" y="2" width="22" height="21" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="-1" dy="-1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.988235 0 0 0 0 0.521569 0 0 0 0 0.0392157 0 0 0 0.8 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3494_96161" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="1" dy="1" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.988235 0 0 0 0 0.521569 0 0 0 0 0.0392157 0 0 0 0.8 0" />
        <feBlend mode="normal" in2="effect1_innerShadow_3494_96161" result="effect2_innerShadow_3494_96161" />
      </filter>
      <filter
        id="filter1_b_3494_96161"
        x="9.875"
        y="9.875"
        width="17.25"
        height="17.25"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_3494_96161" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_3494_96161" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_3494_96161" x1="14" y1="14" x2="23" y2="23" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FE5A13" />
        <stop offset="1" stopColor="#FE5A13" stopOpacity="0" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_3494_96161"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(18.5 18.5) rotate(90) scale(4.5)"
      >
        <stop stopColor="white" stopOpacity="0.5" />
        <stop offset="1" stopColor="#FFCB00" />
      </radialGradient>
    </defs>
  </svg>
);

export const NotificationIcon = ({ type }: { type: NotificationType }) => {
  switch (type) {
    case NotificationType.KocAcceptOrder:
    case NotificationType.KocReceivedOrderInvitation:
      return <InvitationIcon />;
    case NotificationType.KocScriptSubmission:
    case NotificationType.KocFirstDraftSubmission:
    case NotificationType.KocFinalDraftSubmission:
    case NotificationType.ScriptConfirmation:
    case NotificationType.FirstDraftConfirmation:
    case NotificationType.FinalDraftConfirmation:
      return <SubmitIcon />;
    case NotificationType.AdvertiserConfirmation:
      return <ConfirmIcon />;
    case NotificationType.KocMessage:
    case NotificationType.AdvertiserMessage:
      return <ReplyIcon />;
    case NotificationType.PendingSettlement:
      return <PeriodIcon />;
    case NotificationType.Settled:
      return <SettledIcon />;
    case NotificationType.KocOnline:
      return <OnlineIcon />;
  }
};
