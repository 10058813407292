/*
 * @Date: 2024-01-19 12:22:32
 * @LastEditors: wangkai wangkai04@rd.netease.com
 * @LastEditTime: 2024-02-27 15:50:29
 * @Description: KOCv1.0 step3 广告主消息通知部分 国际化
 */
import { defineMessages } from 'react-intl';
export const notificationIntlMessgaes = defineMessages({
  notifications: {
    id: 'advertiser.notify.notifications',
    defaultMessage: 'Notifications',
  },
  unreadCount: {
    id: 'advertiser.notify.unreadCount',
    defaultMessage: '<count></count> unread messages. Click to view details.',
  },
  all: { id: 'advertiser.notify.all', defaultMessage: 'All' },
  unread: { id: 'advertiser.notify.unread', defaultMessage: 'Unread' },
  accepted: {
    id: 'advertiser.notify.accepted',
    defaultMessage: '<kolName></kolName> has accepted your campaign <promotionName></promotionName>. Confirm it in "<influencer></influencer>"',
  },
  submittedScript: {
    id: 'advertiser.notify.submittedScript',
    defaultMessage:
      '<kolName></kolName> has submitted his/her script for your campaign <promotionName></promotionName>. Confirm it inb "<influencer></influencer>" ',
  },
  submittedDraft: {
    id: 'advertiser.notify.submittedDraft',
    defaultMessage:
      '<kolName></kolName> has submitted his/her first draft for your campaign <promotionName></promotionName>. Confirm it in "<influencer></influencer>"',
  },
  submittedFinal: {
    id: 'advertiser.notify.submittedFinal',
    defaultMessage:
      '<kolName></kolName> has submitted his/her final draft for your campaign <promotionName></promotionName>. Confirm it in "<influencer></influencer>" section',
  },
  message: {
    id: 'advertiser.notify.message',
    defaultMessage:
      '<kolName></kolName> has left a comment regarding your campaign <promotionName></promotionName>. Check your message board in "<influencer></influencer>"',
  },
  launched: {
    id: 'advertiser.notify.launched',
    defaultMessage:
      '<kolName></kolName> has launched his/her project regarding your campaign <promotionName></promotionName>. Check your message board in "<influencer></influencer>"',
  },
  influencer: {
    id: 'advertiser.notify.influencer',
    defaultMessage: 'Influencer',
  },
  read: { id: 'advertiser.notify.read', defaultMessage: 'Read' },
  addRead: {
    id: 'advertiser.notify.addRead',
    defaultMessage: 'Mark all as read',
  },
  noMessage: {
    id: 'advertiser.notify.noMessage',
    defaultMessage: 'No news',
  },
  details: { id: 'advertiser.notify.details', defaultMessage: 'View details' },
  showAll: { id: 'advertiser.notify.showAll', defaultMessage: 'Showing all Notifications' },
  invitations: {
    id: 'advertiser.notify.invitations',
    defaultMessage: 'Invitations',
  },
  myOffers: { id: 'advertiser.notify.myOffers', defaultMessage: 'My Offers' },
  myPayment: { id: 'advertiser.notify.myPayment', defaultMessage: 'Payment' },
  // !KOC 端消息
  kocReceivedInvitation: {
    id: 'advertiser.notify.kocReceivedInvitation',
    defaultMessage:
      'You have received an invitation from <promotionName></promotionName> - <kolPlatform></kolPlatform>. To accept it, check <invitations></invitations> section.',
  },
  advertiserConfirmeCooperation: {
    id: 'advertiser.notify.advertiserConfirmeCooperation',
    defaultMessage:
      'Your client has confirmed your acceptance of <promotionName></promotionName> - <kolPlatform></kolPlatform>. Please submit your script. Check details in <myOffers></myOffers>.',
  },
  advertiserConfirmeScript: {
    id: 'advertiser.notify.advertiserConfirmeScript',
    defaultMessage:
      'Your client has confirmed your script for <promotionName></promotionName> - <kolPlatform></kolPlatform>. Please submit your first draft. Check details in <myOffers></myOffers>.',
  },
  advertiserConfirmeFirstScript: {
    id: 'advertiser.notify.advertiserConfirmeFirstScript',
    defaultMessage:
      'Your client has confirmed your script for <promotionName></promotionName> - <kolPlatform></kolPlatform>. Please submit your first draft. Check details in <myOffers></myOffers>.',
  },
  advertiserConfirmeFinalScript: {
    id: 'advertiser.notify.advertiserConfirmeFinalScript',
    defaultMessage:
      'Your client has confirmed your script for <promotionName></promotionName> - <kolPlatform></kolPlatform>. Please submit your final draft. Check details in <myOffers></myOffers>.',
  },
  advertiserMessage: {
    id: 'advertiser.notify.advertiserMessage',
    defaultMessage:
      'Your client has left a comment regarding your <promotionName></promotionName> - <kolPlatform></kolPlatform>. Check your message board in <myOffers></myOffers>.',
  },
  settlementCycleReach: {
    id: 'advertiser.notify.settlementCycleReach',
    defaultMessage:
      'Your <promotionName></promotionName> - <kolPlatform></kolPlatform> has reached the settlement period. Check details in <myOffers></myOffers>.',
  },
  settled: {
    id: 'advertiser.notify.settled',
    defaultMessage:
      'Your <promotionName></promotionName> - <kolPlatform></kolPlatform> has been paid to your youdao Ads account. Check details in <myPayment></myPayment>.',
  },
});
