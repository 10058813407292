import { NotificationListResult, uploadReadOne } from '@/services/notification';
import { NotificationType } from './constants';
import { useRef } from 'react';
import styles from './styles/NotificationItem.module.scss';
import { useIntl } from 'react-intl';
import { notificationIntlMessgaes } from './constants/intl';
import { message } from 'antd';
import { UnreadDot, UserRole } from '@/constants';
import Icon, { IconType } from '@/components/platformIcon';
import { useRouter } from 'next/router';
import useUser from '@/hooks/useUser';
import { NotificationIcon } from './constants/icons';
import classNames from 'classnames';
import PlatformAccount from '@/components/table/PlatformAccount';

const PlatformRender = ({ notification }: { notification: NotificationListResult }) => {
  return (
    <span style={{ verticalAlign: '2px' }}>
      <PlatformAccount
        platform={notification.kolPlatform}
        kolname={notification.kolName}
        avatarUrl={notification.avatarUrl}
        homePageUrl={notification.homePageUrl}
      />
    </span>
  );
};

interface NotificationItemProps {
  notification: NotificationListResult;
  refresh?: () => void;
  handleDrawerOpen?: (id: string | number, platform: string) => Promise<void>;
}

const NotificationItem: React.FC<NotificationItemProps> = ({ notification, refresh, handleDrawerOpen }) => {
  const router = useRouter();
  const { formatMessage } = useIntl();
  const showMsg = useRef<any>('');
  const { role } = useUser();

  // *广告主端跳转
  // 跳转到推广系列详情页
  const gotoPromotionDetail = () => {
    router.push(`/advertising/promotion/detail/${notification.promotionId}`);
  };

  // 跳转到达人社媒主页
  const gotoPartnerDetail = () => {
    router.push(`/advertising/partner/detail?i=${btoa(notification.kolId.toString())}`);
  };

  // 跳转到合作达人- 已接单待确认|进行中|已发布 页面，查询条件默认填充为达人名称和推广系列名称，展示该条待确认合作记录；
  const gotoPartner = (key: string) => {
    router.push(
      `/advertising/partner?kI=${window.btoa(notification.kolId.toString())}&kN=${window.btoa(encodeURIComponent(notification.kolName))}
      &pI=${btoa(notification.promotionId.toString())}&pN=${window.btoa(encodeURIComponent(notification.promotionName))}&k=${window.btoa(key)}`,
    );
  };

  // 跳转到接单邀请页面，切换合作中
  const gotoInvitationPending = () => {
    router.push(`/koc/orders?k=${btoa('0')}`);
  };

  // 跳转到我的任务合作中页面，查询条件默认填充为推广系列名称，展示该条合作中的任务详情key: 'pending', 'done'
  const gotoTask = (key: string) => {
    const pN = btoa(encodeURIComponent(notification.promotionName));
    router.push(`/koc/tasks?pI=${btoa(notification.promotionId.toString())}&kP=${btoa(notification.kolPlatform)}&pN=${pN}&k=${btoa(key)}`);
  };

  const gotoPayment = () => {
    router.push(`/koc/payment`);
  };

  // 通知消息的拼接 & 国家化处理
  switch (notification.type) {
    // !广告主端消息
    // 达人接单
    case NotificationType.KocAcceptOrder:
      showMsg.current = formatMessage(notificationIntlMessgaes.accepted, {
        promotionName: () => (
          <span onClick={gotoPromotionDetail} className={styles.canClick}>
            {' '}
            {notification.promotionName}{' '}
          </span>
        ),
        kolName: () => <span onClick={gotoPartnerDetail} className={styles.canClick}>{`${notification.kolName}`}</span>,
        influencer: () => (
          <span onClick={() => gotoPartner('comfirm')} className={styles.canClick}>
            {formatMessage(notificationIntlMessgaes.influencer)}
          </span>
        ),
      });
      break;
    // 达人脚本提交
    case NotificationType.KocScriptSubmission:
      showMsg.current = formatMessage(notificationIntlMessgaes.submittedScript, {
        promotionName: () => (
          <span onClick={gotoPromotionDetail} className={styles.canClick}>
            {notification.promotionName}
          </span>
        ),
        kolName: () => (
          <span onClick={gotoPartnerDetail} className={styles.canClick}>
            {notification.kolName}
          </span>
        ),
        influencer: () => (
          <span onClick={() => gotoPartner('pending')} className={styles.canClick}>
            {formatMessage(notificationIntlMessgaes.influencer)}
          </span>
        ),
      });
      break;
    // 达人初稿提交
    case NotificationType.KocFirstDraftSubmission:
      showMsg.current = formatMessage(notificationIntlMessgaes.submittedDraft, {
        promotionName: () => (
          <span onClick={gotoPromotionDetail} className={styles.canClick}>
            {notification.promotionName}
          </span>
        ),
        kolName: () => (
          <span onClick={gotoPartnerDetail} className={styles.canClick}>
            {notification.kolName}
          </span>
        ),
        influencer: () => (
          <span onClick={() => gotoPartner('pending')} className={styles.canClick}>
            {formatMessage(notificationIntlMessgaes.influencer)}
          </span>
        ),
      });
      break;
    // 达人终稿提交
    case NotificationType.KocFinalDraftSubmission:
      showMsg.current = formatMessage(notificationIntlMessgaes.submittedFinal, {
        promotionName: () => (
          <span onClick={gotoPromotionDetail} className={styles.canClick}>
            {notification.promotionName}
          </span>
        ),
        kolName: () => (
          <span onClick={gotoPartnerDetail} className={styles.canClick}>
            {notification.kolName}
          </span>
        ),
        influencer: () => (
          <span onClick={() => gotoPartner('pending')} className={styles.canClick}>
            {formatMessage(notificationIntlMessgaes.influencer)}
          </span>
        ),
      });
      break;
    // 达人留言
    case NotificationType.KocMessage:
      showMsg.current = formatMessage(notificationIntlMessgaes.message, {
        promotionName: () => (
          <span onClick={gotoPromotionDetail} className={styles.canClick}>
            {notification.promotionName}
          </span>
        ),
        kolName: () => (
          <span onClick={gotoPartnerDetail} className={styles.canClick}>
            {notification.kolName}
          </span>
        ),
        influencer: () => (
          <span onClick={() => gotoPartner('pending')} className={styles.canClick}>
            {formatMessage(notificationIntlMessgaes.influencer)}
          </span>
        ),
      });
      break;
    // 达人上线
    case NotificationType.KocOnline:
      showMsg.current = formatMessage(notificationIntlMessgaes.launched, {
        promotionName: () => (
          <span onClick={gotoPromotionDetail} className={styles.canClick}>
            {notification.promotionName}
          </span>
        ),
        kolName: () => (
          <span onClick={gotoPartnerDetail} className={styles.canClick}>
            {notification.kolName}
          </span>
        ),
        influencer: () => (
          <span onClick={() => gotoPartner('done')} className={styles.canClick}>
            {formatMessage(notificationIntlMessgaes.influencer)}
          </span>
        ),
      });
      break;

    // !KOC端消息
    // KOC收到接单邀请
    case NotificationType.KocReceivedOrderInvitation:
      showMsg.current = formatMessage(notificationIntlMessgaes.kocReceivedInvitation, {
        promotionName: () => <span>{notification.promotionName}</span>,
        kolPlatform: () => <PlatformRender notification={notification} />,
        invitations: () => (
          <span
            onClick={(e) => {
              e.stopPropagation();
              gotoInvitationPending();
            }}
            className={styles.canClick}
          >
            {formatMessage(notificationIntlMessgaes.invitations)}
          </span>
        ),
      });
      break;
    // 广告主确认合作
    case NotificationType.AdvertiserConfirmation:
      showMsg.current = formatMessage(notificationIntlMessgaes.advertiserConfirmeCooperation, {
        promotionName: () => <span>{notification.promotionName}</span>,
        kolPlatform: () => <PlatformRender notification={notification} />,
        myOffers: () => (
          <span
            onClick={(e) => {
              e.stopPropagation();
              gotoTask('pending');
            }}
            className={styles.canClick}
          >
            {formatMessage(notificationIntlMessgaes.myOffers)}
          </span>
        ),
      });
      break;
    // 脚本确认
    case NotificationType.ScriptConfirmation:
      showMsg.current = formatMessage(notificationIntlMessgaes.advertiserConfirmeScript, {
        promotionName: () => <span>{notification.promotionName}</span>,
        kolPlatform: () => <PlatformRender notification={notification} />,
        myOffers: () => (
          <span
            onClick={(e) => {
              e.stopPropagation();
              gotoTask('pending');
            }}
            className={styles.canClick}
          >
            {formatMessage(notificationIntlMessgaes.myOffers)}
          </span>
        ),
      });
      break;
    // 初稿确认
    case NotificationType.FirstDraftConfirmation:
      showMsg.current = formatMessage(notificationIntlMessgaes.advertiserConfirmeFirstScript, {
        promotionName: () => <span>{notification.promotionName}</span>,
        kolPlatform: () => <PlatformRender notification={notification} />,
        myOffers: () => (
          <span
            onClick={(e) => {
              e.stopPropagation();
              gotoTask('pending');
            }}
            className={styles.canClick}
          >
            {formatMessage(notificationIntlMessgaes.myOffers)}
          </span>
        ),
      });
      break;
    // 终稿确认
    case NotificationType.FinalDraftConfirmation:
      showMsg.current = formatMessage(notificationIntlMessgaes.advertiserConfirmeFinalScript, {
        promotionName: () => <span>{notification.promotionName}</span>,
        kolPlatform: () => <PlatformRender notification={notification} />,
        myOffers: () => (
          <span
            onClick={(e) => {
              e.stopPropagation();
              gotoTask('pending');
            }}
            className={styles.canClick}
          >
            {formatMessage(notificationIntlMessgaes.myOffers)}
          </span>
        ),
      });
      break;
    // 广告主留言
    case NotificationType.AdvertiserMessage:
      showMsg.current = formatMessage(notificationIntlMessgaes.advertiserMessage, {
        promotionName: () => <span>{notification.promotionName}</span>,
        kolPlatform: () => <PlatformRender notification={notification} />,
        myOffers: () => (
          <span
            onClick={(e) => {
              e.stopPropagation();
              gotoTask('pending');
            }}
            className={styles.canClick}
          >
            {formatMessage(notificationIntlMessgaes.myOffers)}
          </span>
        ),
      });
      break;
    // 待结算
    case NotificationType.PendingSettlement:
      showMsg.current = formatMessage(notificationIntlMessgaes.settlementCycleReach, {
        promotionName: () => <span>{notification.promotionName}</span>,
        kolPlatform: () => <PlatformRender notification={notification} />,
        myOffers: () => (
          <span
            onClick={(e) => {
              e.stopPropagation();
              gotoTask('done');
            }}
            className={styles.canClick}
          >
            {formatMessage(notificationIntlMessgaes.myOffers)}
          </span>
        ),
      });
      break;
    // 已结算
    case NotificationType.Settled:
      showMsg.current = formatMessage(notificationIntlMessgaes.settled, {
        promotionName: () => <span>{notification.promotionName}</span>,
        kolPlatform: () => <PlatformRender notification={notification} />,
        myPayment: () => (
          <span
            onClick={(e) => {
              e.stopPropagation();
              gotoPayment();
            }}
            className={styles.canClick}
          >
            {formatMessage(notificationIntlMessgaes.myPayment)}
          </span>
        ),
      });
      break;
  }

  // 把一条通知设为已读
  const handleReadClick = async () => {
    try {
      await uploadReadOne({ notificationId: notification.notificationId });
      // 刷新页面
      refresh && refresh();
    } catch (error) {
      message.error('操作失败');
    }
  };

  return (
    <div
      className={classNames(styles.notification, {
        [styles.clickable]: role === UserRole.KOC,
        [styles.nonInteractive]: !handleDrawerOpen,
      })}
      onClick={() => {
        if (!handleDrawerOpen) return;
        if (role === UserRole.KOC) {
          handleDrawerOpen(notification.promotionId, notification.kolPlatform);
        }
        handleReadClick();
      }}
    >
      <div className={styles.notificationIcons}>
        <span className={styles.typeIcon}>{<NotificationIcon type={notification.type} />}</span>
        <span className={styles.unreadDot}>{!notification.readStatus && <UnreadDot />}</span>
      </div>
      <div className={styles.messageInfo}>
        <div className={styles.message}>{showMsg.current}</div>
        <div className={styles.time}>{notification.createTime}</div>
      </div>
    </div>
  );
};

export default NotificationItem;
