export const OthersIcon = (id: string) => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id={id} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="3" y="3" width="30" height="30">
        <rect x="3.99984" y="4.00008" width="28" height="28" rx="14" fill="#111927" stroke="white" strokeWidth="1.33333" />
      </mask>
      <g mask={`url(#${id})`}>
        <path
          d="M35.4998 31.7223V21.2761C35.4998 20.1716 34.6044 19.2761 33.4998 19.2761H21.2998C20.1952 19.2761 19.2998 20.1716 19.2998 21.2761V31.5001C19.2998 32.6047 20.1952 33.5001 21.2998 33.5001H33.6998C34.6939 33.5001 35.4998 32.7042 35.4998 31.7223Z"
          fill="#FC850A"
        />
        <path
          d="M16.7 31.5001V21.2761C16.7 20.1716 15.8046 19.2761 14.7 19.2761H2.5C1.39543 19.2761 0.5 20.1716 0.5 21.2761V31.7223C0.5 32.7042 1.3059 33.5001 2.3 33.5001H14.7C15.8046 33.5001 16.7 32.6047 16.7 31.5001Z"
          fill="#23D011"
        />
        <path
          d="M35.4998 4.27802C35.4998 3.29619 34.6939 2.50024 33.6998 2.50024H21.2998C20.1952 2.50024 19.2998 3.39567 19.2998 4.50024V14.7207C19.2998 15.8253 20.1952 16.7207 21.2998 16.7207H33.4998C34.6044 16.7207 35.4998 15.8253 35.4998 14.7207V4.27802Z"
          fill="#315EFF"
        />
        <path
          d="M16.7 14.7207V4.50024C16.7 3.39567 15.8046 2.50024 14.7 2.50024H2.3C1.3059 2.50024 0.5 3.29619 0.5 4.27802V14.7207C0.5 15.8253 1.39543 16.7207 2.5 16.7207H14.7C15.8046 16.7207 16.7 15.8253 16.7 14.7207Z"
          fill="#FF4E16"
        />
        <rect x="3.99984" y="4.00008" width="28" height="28" rx="14" stroke="white" strokeWidth="1.33333" />
      </g>
    </svg>
  );
};

export const OthersGrayIcon = (id: string) => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id={id} style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="3" y="3" width="30" height="30">
        <rect x="3.99984" y="4.00008" width="28" height="28" rx="14" fill="#111927" stroke="white" strokeWidth="1.33333" />
      </mask>
      <g mask={`url(#${id})`}>
        <path
          d="M35.4998 31.7223V21.2761C35.4998 20.1716 34.6044 19.2761 33.4998 19.2761H21.2998C20.1952 19.2761 19.2998 20.1716 19.2998 21.2761V31.5001C19.2998 32.6047 20.1952 33.5001 21.2998 33.5001H33.6998C34.6939 33.5001 35.4998 32.7042 35.4998 31.7223Z"
          fill="#D9DEE5"
        />
        <path
          d="M16.7 31.5001V21.2761C16.7 20.1716 15.8046 19.2761 14.7 19.2761H2.5C1.39543 19.2761 0.5 20.1716 0.5 21.2761V31.7223C0.5 32.7042 1.3059 33.5001 2.3 33.5001H14.7C15.8046 33.5001 16.7 32.6047 16.7 31.5001Z"
          fill="#D9DEE5"
        />
        <path
          d="M35.4998 4.27802C35.4998 3.29619 34.6939 2.50024 33.6998 2.50024H21.2998C20.1952 2.50024 19.2998 3.39567 19.2998 4.50024V14.7207C19.2998 15.8253 20.1952 16.7207 21.2998 16.7207H33.4998C34.6044 16.7207 35.4998 15.8253 35.4998 14.7207V4.27802Z"
          fill="#D9DEE5"
        />
        <path
          d="M16.7 14.7207V4.50024C16.7 3.39567 15.8046 2.50024 14.7 2.50024H2.3C1.3059 2.50024 0.5 3.29619 0.5 4.27802V14.7207C0.5 15.8253 1.39543 16.7207 2.5 16.7207H14.7C15.8046 16.7207 16.7 15.8253 16.7 14.7207Z"
          fill="#D9DEE5"
        />
        <rect x="3.99984" y="4.00008" width="28" height="28" rx="14" stroke="white" strokeWidth="1.33333" />
      </g>
    </svg>
  );
};
