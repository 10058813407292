import React from 'react';

import { YoutubeGrayIcon, YoutubeOutlineIcon, YoutubeIcon } from './Youtube';
import { TwitchGrayIcon, TwitchOutlineIcon, TwitchIcon } from './Twitch';
import { TwitterGrayIcon, TwitterOutlineIcon, TwitterIcon } from './Twitter';
import { TiktokGrayIcon, TiktokOutlineIcon, TiktokIcon } from './Tiktok';
import { InstagramGrayIcon, InstagramOutlineIcon, InstagramIcon } from './Instagram';
import { FacebookGrayIcon, FacebookOutlineIcon, FacebookIcon } from './Facebook';
import { OthersIcon, OthersGrayIcon } from './DefaultAvatar';

const icons: Record<string, () => JSX.Element> = {
  youtubeOutline: YoutubeOutlineIcon,
  youtubeGray: YoutubeGrayIcon,
  youtubeIcon: YoutubeIcon,

  twitchOutline: TwitchOutlineIcon,
  twitchGray: TwitchGrayIcon,
  twitchIcon: TwitchIcon,

  twitterOutline: TwitterOutlineIcon,
  twitterGray: TwitterGrayIcon,
  twitterIcon: TwitterIcon,

  tiktokOutline: TiktokOutlineIcon,
  tiktokGray: TiktokGrayIcon,
  tiktokIcon: TiktokIcon,
  igIcon: () => InstagramIcon(Math.random().toString()),
  instiktoksIcon: () => InstagramIcon(Math.random().toString()),
  instagramOutline: () => InstagramOutlineIcon(Math.random().toString(), Math.random().toString(), Math.random().toString()),
  instagramGray: InstagramGrayIcon,
  instagramIcon: () => InstagramIcon(Math.random().toString()),

  facebookOutline: FacebookOutlineIcon,
  facebookGray: FacebookGrayIcon,
  facebookIcon: FacebookIcon,

  othersOutline: () => OthersIcon(Math.random().toString()),
  othersGray: () => OthersGrayIcon(Math.random().toString()),
  othersIcon: () => OthersIcon(Math.random().toString()),
};

export type IconType = keyof typeof icons;

interface IconProps {
  type: IconType;
}

const Icon: React.FC<IconProps> = ({ type }) => {
  const component = icons[type];
  if (component === undefined) {
    return null;
  }
  return React.createElement(component);
};

export default Icon;
